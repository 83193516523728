<template>
  <div class="home">
    <div class="bg" :style="{ background: bgColor }"></div>
    <div class="container">
      <div class="logo">
        <div class="logo-bg"></div>
      </div>
      <div class="colors">
        <div
          class="color-box"
          :key="index"
          v-for="(item, index) in colorList"
          @click="getColorItem(item)"
          v-clipboard:copy="item.hex"
          v-clipboard:success="onCopy"
        >
          <color-item :item="item" />
        </div>
      </div>
      <div class="content">
        <div class="content-color">
          <div class="circle-container" v-for="(item, index) in cmyk" :key="item.title">
            <div class="circle-title">{{item.title}}</div>
            <div class="circle" :style="{color: item.color}">
              {{currentCmky[index]}}
            </div>
            <div class="circle-right">
              <div class="line" 
                v-if="currentCmky[index] >= 50"
                :style="{transform: `rotate(${180}deg)`}"></div>
              <div class="line" 
                v-else
                :style="{transform: `rotate(${3.6 * currentCmky[index]}deg)`}"></div>
            </div>
            <div class="circle-left">
              <div class="line"
              v-if="currentCmky[index] >= 50"
              :style="{transform: `rotate(${3.6 * currentCmky[index]}deg)`}"></div>
              <div class="line"
              v-else
              :style="{transform: `rotate(${180}deg)`}"></div>
            </div>
          </div>
        </div>
        <div class="content-num">
          <num-rgb title="R" ref="num" :numbers="RGB[0]" />
          <num-rgb title="G" :numbers="RGB[1]" />
          <num-rgb title="B" :numbers="RGB[2]" />
        </div>
        <div class="content-name">
          <div class="content-name__chinese">
            {{chineseName}}
          </div>
          <div class="content-name__pinyin">
            {{chinsesPinyin.toUpperCase()}}
          </div>
        </div>
        <div class="tip-box" v-show="tipShow">
          <div class="tip">复制成功，{{bgColor}}！</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorItem from "../components/ColorItem";
import NumRgb from '../components/Num'
export default {
  name: "Home",
  components: {
    ColorItem,
    NumRgb
  },
  data() {
    return {
      colorList: [
        {
          CMYK: [4, 5, 18, 0],
          RGB: [249, 244, 220],
          hex: "#f9f4dc",
          name: "\u4e73\u767d",
          pinyin: "rubai",
        },
        {
          CMYK: [3, 8, 30, 0],
          RGB: [249, 236, 195],
          hex: "#f7e8aa",
          name: "\u674f\u4ec1\u9ec4",
          pinyin: "xingrenhuang",
        },
        {
          CMYK: [4, 13, 67, 0],
          RGB: [248, 223, 114],
          hex: "#f8df72",
          name: "\u8309\u8389\u9ec4",
          pinyin: "molihuang",
        },
        {
          CMYK: [5, 14, 68, 1],
          RGB: [248, 223, 112],
          hex: "#f8df70",
          name: "\u9ea6\u79c6\u9ec4",
          pinyin: "maiganhuang",
        },
        {
          CMYK: [2, 16, 84, 0],
          RGB: [251, 218, 65],
          hex: "#fbda41",
          name: "\u6cb9\u83dc\u82b1\u9ec4",
          pinyin: "youcaihuahuang",
        },
        {
          CMYK: [1, 18, 94, 0],
          RGB: [254, 215, 26],
          hex: "#fed71a",
          name: "\u4f5b\u624b\u9ec4",
          pinyin: "foshouhuang",
        },
        {
          CMYK: [3, 16, 50, 0],
          RGB: [247, 222, 152],
          hex: "#f7de98",
          name: "\u7bfe\u9ec4",
          pinyin: "miehuang",
        },
        {
          CMYK: [3, 17, 69, 0],
          RGB: [248, 216, 106],
          hex: "#f8d86a",
          name: "\u8475\u6247\u9ec4",
          pinyin: "kuishanhuang",
        },
        {
          CMYK: [0, 20, 87, 0],
          RGB: [252, 211, 55],
          hex: "#fcd337",
          name: "\u67e0\u6aac\u9ec4",
          pinyin: "ningmenghuang",
        },
        {
          CMYK: [0, 20, 95, 0],
          RGB: [252, 210, 23],
          hex: "#fcd217",
          name: "\u91d1\u74dc\u9ec4",
          pinyin: "jinguahuang",
        },
        {
          CMYK: [0, 21, 94, 0],
          RGB: [254, 209, 16],
          hex: "#ffd111",
          name: "\u85e4\u9ec4",
          pinyin: "tenghuang",
        },
        {
          CMYK: [2, 16, 39, 0],
          RGB: [246, 222, 173],
          hex: "#f6dead",
          name: "\u916a\u9ec4",
          pinyin: "laohuang",
        },
        {
          CMYK: [1, 17, 50, 0],
          RGB: [247, 218, 148],
          hex: "#f7da94",
          name: "\u9999\u6c34\u73ab\u7470\u9ec4",
          pinyin: "xiangshuimeiguihuang",
        },
        {
          CMYK: [1, 21, 70, 0],
          RGB: [249, 211, 103],
          hex: "#f9d367",
          name: "\u6de1\u5bc6\u9ec4",
          pinyin: "danmihuang",
        },
        {
          CMYK: [0, 23, 88, 0],
          RGB: [251, 205, 49],
          hex: "#fbcd31",
          name: "\u5927\u8c46\u9ec4",
          pinyin: "dadouhuang",
        },
        {
          CMYK: [0, 24, 94, 0],
          RGB: [252, 203, 22],
          hex: "#fccb16",
          name: "\u7d20\u99a8\u9ec4",
          pinyin: "suxinhuang",
        },
        {
          CMYK: [0, 24, 94, 0],
          RGB: [254, 204, 17],
          hex: "#fecc11",
          name: "\u5411\u65e5\u8475\u9ec4",
          pinyin: "xiangrikuihuang",
        },
        {
          CMYK: [0, 27, 88, 0],
          RGB: [251, 200, 47],
          hex: "#fbc82f",
          name: "\u96c5\u68a8\u9ec4",
          pinyin: "yalihuang",
        },
        {
          CMYK: [0, 28, 94, 0],
          RGB: [252, 197, 21],
          hex: "#fcc515",
          name: "\u9ec4\u8fde\u9ec4",
          pinyin: "huanglianhuang",
        },
        {
          CMYK: [0, 29, 95, 0],
          RGB: [252, 195, 7],
          hex: "#fcc307",
          name: "\u91d1\u76cf\u9ec4",
          pinyin: "jinzhanhuang",
        },
        {
          CMYK: [0, 32, 52, 0],
          RGB: [248, 195, 135],
          hex: "#f8c387",
          name: "\u86cb\u58f3\u9ec4",
          pinyin: "dankehuang",
        },
        {
          CMYK: [0, 32, 52, 0],
          RGB: [247, 193, 115],
          hex: "#f7c173",
          name: "\u8089\u8272",
          pinyin: "rouse",
        },
        {
          CMYK: [0, 35, 89, 0],
          RGB: [251, 185, 41],
          hex: "#fbb929",
          name: "\u9e45\u638c\u9ec4",
          pinyin: "ezhanghuang",
        },
        {
          CMYK: [0, 36, 93, 0],
          RGB: [251, 182, 18],
          hex: "#fbb612",
          name: "\u9e21\u86cb\u9ec4",
          pinyin: "jidanhuang",
        },
        {
          CMYK: [0, 35, 94, 0],
          RGB: [252, 183, 10],
          hex: "#fcb70a",
          name: "\u9f2c\u9ec4",
          pinyin: "youhuang",
        },
        {
          CMYK: [0, 44, 84, 0],
          RGB: [249, 166, 51],
          hex: "#f9a633",
          name: "\u69b4\u843c\u9ec4",
          pinyin: "liuehuang",
        },
        {
          CMYK: [0, 45, 92, 0],
          RGB: [251, 164, 20],
          hex: "#fba414",
          name: "\u6de1\u6a58\u6a59",
          pinyin: "danjucheng",
        },
        {
          CMYK: [0, 47, 92, 0],
          RGB: [252, 161, 6],
          hex: "#fca106",
          name: "\u6787\u6777\u9ec4",
          pinyin: "pipahuang",
        },
        {
          CMYK: [0, 47, 92, 0],
          RGB: [252, 161, 4],
          hex: "#fca104",
          name: "\u6a59\u76ae\u9ec4",
          pinyin: "chengpihuang",
        },
        {
          CMYK: [0, 56, 87, 0],
          RGB: [252, 140, 35],
          hex: "#fc8c23",
          name: "\u5317\u74dc\u9ec4",
          pinyin: "beiguahuang",
        },
        {
          CMYK: [0, 54, 92, 0],
          RGB: [250, 142, 22],
          hex: "#f28e16",
          name: "\u674f\u9ec4",
          pinyin: "xinghuang",
        },
        {
          CMYK: [0, 51, 91, 0],
          RGB: [255, 153, 0],
          hex: "#ff9900",
          name: "\u96c4\u9ec4",
          pinyin: "xionghuang",
        },
        {
          CMYK: [0, 56, 91, 0],
          RGB: [251, 139, 5],
          hex: "#fb8b05",
          name: "\u4e07\u5bff\u83ca\u9ec4",
          pinyin: "wanshoujuhuang",
        },
        {
          CMYK: [10, 13, 35, 1],
          RGB: [233, 221, 182],
          hex: "#e9ddb6",
          name: "\u83ca\u857e\u767d",
          pinyin: "juleibai",
        },
        {
          CMYK: [8, 19, 84, 1],
          RGB: [238, 208, 69],
          hex: "#eed045",
          name: "\u79cb\u8475\u9ec4",
          pinyin: "qiukuihuang",
        },
        {
          CMYK: [6, 20, 92, 1],
          RGB: [242, 206, 43],
          hex: "#f2ce2b",
          name: "\u786b\u534e\u9ec4",
          pinyin: "liuhuahuang",
        },
        {
          CMYK: [6, 22, 92, 0],
          RGB: [241, 202, 23],
          hex: "#f1ca17",
          name: "\u67da\u9ec4",
          pinyin: "youhuang",
        },
        {
          CMYK: [15, 20, 66, 2],
          RGB: [221, 200, 113],
          hex: "#ddc871",
          name: "\u8292\u679c\u9ec4",
          pinyin: "mangguohuang",
        },
        {
          CMYK: [14, 22, 85, 2],
          RGB: [223, 194, 67],
          hex: "#dfc243",
          name: "\u84bf\u9ec4",
          pinyin: "haohuang",
        },
        {
          CMYK: [12, 24, 95, 2],
          RGB: [226, 192, 39],
          hex: "#e2c027",
          name: "\u59dc\u9ec4",
          pinyin: "jianghuang",
        },
        {
          CMYK: [11, 25, 99, 1],
          RGB: [228, 191, 17],
          hex: "#e4bf11",
          name: "\u9999\u8549\u9ec4",
          pinyin: "xiangjiaohuang",
        },
        {
          CMYK: [17, 27, 94, 4],
          RGB: [210, 180, 44],
          hex: "#d2b42c",
          name: "\u8349\u9ec4",
          pinyin: "caohuang",
        },
        {
          CMYK: [17, 29, 100, 4],
          RGB: [210, 177, 22],
          hex: "#d2b116",
          name: "\u65b0\u79be\u7eff",
          pinyin: "xinhelv",
        },
        {
          CMYK: [28, 26, 45, 7],
          RGB: [183, 174, 143],
          hex: "#b7ae8f",
          name: "\u6708\u7070",
          pinyin: "yuehui",
        },
        {
          CMYK: [30, 30, 70, 12],
          RGB: [173, 158, 85],
          hex: "#ad9e5f",
          name: "\u6de1\u7070\u7eff",
          pinyin: "danhuilv",
        },
        {
          CMYK: [38, 38, 76, 24],
          RGB: [142, 128, 75],
          hex: "#8e804b",
          name: "\u8349\u7070\u7eff",
          pinyin: "caohuilv",
        },
        {
          CMYK: [36, 42, 100, 29],
          RGB: [136, 115, 34],
          hex: "#887322",
          name: "\u82d4\u7eff",
          pinyin: "tailv",
        },
        {
          CMYK: [36, 42, 100, 30],
          RGB: [134, 112, 24],
          hex: "#867018",
          name: "\u78a7\u87ba\u6625\u7eff",
          pinyin: "biluochunlv",
        },
        {
          CMYK: [47, 47, 65, 42],
          RGB: [104, 94, 72],
          hex: "#685e48",
          name: "\u71d5\u7fbd\u7070",
          pinyin: "yanyuhui",
        },
        {
          CMYK: [46, 47, 69, 42],
          RGB: [105, 94, 69],
          hex: "#695e45",
          name: "\u87f9\u58f3\u7070",
          pinyin: "xiekehui",
        },
        {
          CMYK: [48, 48, 100, 45],
          RGB: [100, 88, 34],
          hex: "#645822",
          name: "\u6f6d\u6c34\u7eff",
          pinyin: "tanshuilv",
        },
        {
          CMYK: [50, 50, 10, 48],
          RGB: [94, 83, 20],
          hex: "#5e5314",
          name: "\u6a44\u6984\u7eff",
          pinyin: "ganlanlv",
        },
        {
          CMYK: [2, 6, 18, 0],
          RGB: [249, 241, 219],
          hex: "#f9f1db",
          name: "\u868c\u8089\u767d",
          pinyin: "bangroubai",
        },
        {
          CMYK: [3, 10, 31, 0],
          RGB: [248, 232, 193],
          hex: "#f8e8c1",
          name: "\u8c46\u6c41\u9ec4",
          pinyin: "douzhihuang",
        },
        {
          CMYK: [1, 19, 66, 0],
          RGB: [249, 215, 112],
          hex: "#f9d770",
          name: "\u6de1\u8327\u9ec4",
          pinyin: "danjianhuang",
        },
        {
          CMYK: [0, 26, 94, 0],
          RGB: [255, 201, 12],
          hex: "#ffc90c",
          name: "\u4e73\u9e2d\u9ec4",
          pinyin: "ruyahuang",
        },
        {
          CMYK: [5, 11, 22, 0],
          RGB: [242, 230, 206],
          hex: "#f2e6ce",
          name: "\u8354\u8089\u767d",
          pinyin: "liroubai",
        },
        {
          CMYK: [5, 19, 50, 0],
          RGB: [240, 214, 149],
          hex: "#f0d695",
          name: "\u8c61\u7259\u9ec4",
          pinyin: "xiangyahuang",
        },
        {
          CMYK: [3, 23, 69, 0],
          RGB: [244, 206, 105],
          hex: "#f4ce69",
          name: "\u7092\u7c73\u9ec4",
          pinyin: "chaomihuang",
        },
        {
          CMYK: [1, 28, 89, 0],
          RGB: [246, 196, 48],
          hex: "#f6c430",
          name: "\u9e66\u9e49\u51a0\u9ec4",
          pinyin: "yingwuguanhuang",
        },
        {
          CMYK: [0, 30, 95, 0],
          RGB: [249, 193, 22],
          hex: "#f9c116",
          name: "\u6728\u74dc\u9ec4",
          pinyin: "muguahuang",
        },
        {
          CMYK: [0, 32, 95, 0],
          RGB: [249, 189, 16],
          hex: "#f9bd10",
          name: "\u6d45\u70d9\u9ec4",
          pinyin: "qianlaohuang",
        },
        {
          CMYK: [11, 18, 39, 1],
          RGB: [229, 211, 170],
          hex: "#e5d3aa",
          name: "\u83b2\u5b50\u767d",
          pinyin: "lianzibai",
        },
        {
          CMYK: [5, 35, 99, 0],
          RGB: [232, 176, 4],
          hex: "#e8b004",
          name: "\u8c37\u9ec4",
          pinyin: "guhuang",
        },
        {
          CMYK: [3, 36, 99, 0],
          RGB: [235, 177, 13],
          hex: "#ebb10d",
          name: "\u6800\u5b50\u9ec4",
          pinyin: "zhizihuang",
        },
        {
          CMYK: [11, 39, 100, 2],
          RGB: [217, 164, 14],
          hex: "#d9a40e",
          name: "\u82a5\u9ec4",
          pinyin: "jiehuang",
        },
        {
          CMYK: [28, 27, 43, 8],
          RGB: [181, 170, 144],
          hex: "#b5aa90",
          name: "\u94f6\u9f20\u7070",
          pinyin: "yinshuhui",
        },
        {
          CMYK: [26, 31, 57, 10],
          RGB: [182, 164, 118],
          hex: "#b6a476",
          name: "\u5c18\u7070",
          pinyin: "chenhui",
        },
        {
          CMYK: [21, 43, 100, 11],
          RGB: [183, 141, 18],
          hex: "#b78d12",
          name: "\u67af\u7eff",
          pinyin: "kulv",
        },
        {
          CMYK: [35, 44, 80, 30],
          RGB: [135, 114, 62],
          hex: "#87723e",
          name: "\u9c9b\u9752",
          pinyin: "jiaoqing",
        },
        {
          CMYK: [32, 50, 100, 31],
          RGB: [135, 104, 24],
          hex: "#876818",
          name: "\u7cbd\u53f6\u7eff",
          pinyin: "zongyelv",
        },
        {
          CMYK: [31, 51, 100, 30],
          RGB: [138, 105, 19],
          hex: "#8a6913",
          name: "\u7070\u7eff",
          pinyin: "huilv",
        },
        {
          CMYK: [52, 56, 64, 62],
          RGB: [74, 64, 53],
          hex: "#4a4035",
          name: "\u9e64\u7070",
          pinyin: "hehui",
        },
        {
          CMYK: [48, 58, 70, 62],
          RGB: [77, 64, 48],
          hex: "#4d4030",
          name: "\u6de1\u677e\u70df",
          pinyin: "dansongyan",
        },
        {
          CMYK: [45, 56, 100, 56],
          RGB: [88, 71, 23],
          hex: "#584717",
          name: "\u6697\u6d77\u6c34\u7eff",
          pinyin: "anhaishuilv",
        },
        {
          CMYK: [45, 55, 100, 54],
          RGB: [91, 73, 19],
          hex: "#5b4913",
          name: "\u68d5\u6988\u7eff",
          pinyin: "zonglvlv",
        },
        {
          CMYK: [1, 11, 24, 0],
          RGB: [249, 223, 205],
          hex: "#f9e9cd",
          name: "\u7c73\u8272",
          pinyin: "mise",
        },
        {
          CMYK: [1, 15, 38, 0],
          RGB: [248, 224, 176],
          hex: "#f8e0b0",
          name: "\u6de1\u8089\u8272",
          pinyin: "danrouse",
        },
        {
          CMYK: [0, 23, 59, 0],
          RGB: [249, 210, 125],
          hex: "#f9d27d",
          name: "\u9ea6\u82bd\u7cd6\u9ec4",
          pinyin: "maiyatanghuang",
        },
        {
          CMYK: [0, 34, 93, 0],
          RGB: [254, 186, 7],
          hex: "#feba07",
          name: "\u7425\u73c0\u9ec4",
          pinyin: "hupohuang",
        },
        {
          CMYK: [1, 31, 79, 0],
          RGB: [243, 191, 76],
          hex: "#f3bf4c",
          name: "\u7518\u8349\u9ec4",
          pinyin: "gancaohuang",
        },
        {
          CMYK: [0, 33, 83, 0],
          RGB: [248, 188, 49],
          hex: "#f8bc31",
          name: "\u521d\u719f\u674f\u9ec4",
          pinyin: "chushuxinghuang",
        },
        {
          CMYK: [10, 27, 59, 1],
          RGB: [226, 193, 124],
          hex: "#e2c17c",
          name: "\u6d45\u9a7c\u8272",
          pinyin: "qiantuose",
        },
        {
          CMYK: [7, 32, 78, 1],
          RGB: [229, 183, 81],
          hex: "#e5b751",
          name: "\u6c99\u77f3\u9ec4",
          pinyin: "shashihuang",
        },
        {
          CMYK: [3, 38, 97, 0],
          RGB: [234, 173, 26],
          hex: "#eaad1a",
          name: "\u864e\u76ae\u9ec4",
          pinyin: "hupihuang",
        },
        {
          CMYK: [12, 41, 98, 2],
          RGB: [214, 160, 29],
          hex: "#d6a01d",
          name: "\u571f\u9ec4",
          pinyin: "tuhuang",
        },
        {
          CMYK: [28, 28, 41, 9],
          RGB: [180, 169, 146],
          hex: "#b4a992",
          name: "\u767e\u7075\u9e1f\u7070",
          pinyin: "bailingniaohui",
        },
        {
          CMYK: [21, 44, 97, 11],
          RGB: [183, 139, 38],
          hex: "#b78b26",
          name: "\u5c71\u9e21\u9ec4",
          pinyin: "shanjihuang",
        },
        {
          CMYK: [35, 47, 71, 33],
          RGB: [130, 107, 72],
          hex: "#826b48",
          name: "\u9f9f\u80cc\u9ec4",
          pinyin: "guibeihuang",
        },
        {
          CMYK: [34, 52, 85, 35],
          RGB: [128, 99, 50],
          hex: "#806332",
          name: "\u82cd\u9ec4",
          pinyin: "canghuang",
        },
        {
          CMYK: [32, 56, 96, 34],
          RGB: [129, 95, 37],
          hex: "#815f25",
          name: "\u83b1\u9633\u68a8\u9ec4",
          pinyin: "laiyanglihuang",
        },
        {
          CMYK: [31, 57, 100, 33],
          RGB: [131, 94, 29],
          hex: "#835e1d",
          name: "\u8734\u870a\u7eff",
          pinyin: "yililv",
        },
        {
          CMYK: [46, 59, 68, 61],
          RGB: [79, 64, 50],
          hex: "#4f4032",
          name: "\u677e\u9f20\u7070",
          pinyin: "songshuhui",
        },
        {
          CMYK: [44, 61, 76, 62],
          RGB: [80, 62, 42],
          hex: "#503e2a",
          name: "\u6a44\u6984\u7070",
          pinyin: "ganlanhui",
        },
        {
          CMYK: [43, 63, 88, 61],
          RGB: [81, 60, 32],
          hex: "#513c20",
          name: "\u87f9\u58f3\u7eff",
          pinyin: "xiekelv",
        },
        {
          CMYK: [42, 64, 94, 60],
          RGB: [83, 60, 27],
          hex: "#533c1b",
          name: "\u53e4\u94dc\u7eff",
          pinyin: "gutonglv",
        },
        {
          CMYK: [41, 66, 94, 60],
          RGB: [85, 59, 24],
          hex: "#553b18",
          name: "\u7126\u8336\u7eff",
          pinyin: "jiaochalv",
        },
        {
          CMYK: [1, 7, 13, 0],
          RGB: [251, 242, 227],
          hex: "#fbf2e3",
          name: "\u7c89\u767d",
          pinyin: "fenbai",
        },
        {
          CMYK: [1, 12, 22, 0],
          RGB: [249, 232, 208],
          hex: "#f9e8d0",
          name: "\u843d\u82f1\u6de1\u7c89",
          pinyin: "luoyingdanfen",
        },
        {
          CMYK: [0, 27, 51, 0],
          RGB: [249, 203, 139],
          hex: "#f9cb8b",
          name: "\u74dc\u74e4\u7c89",
          pinyin: "guarangfen",
        },
        {
          CMYK: [0, 36, 72, 0],
          RGB: [251, 185, 87],
          hex: "#fbb957",
          name: "\u871c\u9ec4",
          pinyin: "mihuang",
        },
        {
          CMYK: [0, 44, 91, 0],
          RGB: [255, 166, 15],
          hex: "#ffa60f",
          name: "\u91d1\u53f6\u9ec4",
          pinyin: "jinyehuang",
        },
        {
          CMYK: [0, 43, 82, 0],
          RGB: [244, 168, 58],
          hex: "#f4a83a",
          name: "\u91d1\u83ba\u9ec4",
          pinyin: "jinyinghuang",
        },
        {
          CMYK: [8, 31, 50, 1],
          RGB: [227, 189, 141],
          hex: "#e3bd8d",
          name: "\u9e7f\u89d2\u68d5",
          pinyin: "lujiaozong",
        },
        {
          CMYK: [2, 44, 83, 0],
          RGB: [231, 162, 63],
          hex: "#e7a23f",
          name: "\u51cb\u53f6\u68d5",
          pinyin: "diaoyezong",
        },
        {
          CMYK: [10, 41, 72, 1],
          RGB: [218, 164, 90],
          hex: "#daa45a",
          name: "\u73b3\u7441\u9ec4",
          pinyin: "daimaohuang",
        },
        {
          CMYK: [7, 45, 82, 1],
          RGB: [222, 158, 68],
          hex: "#de9e44",
          name: "\u8f6f\u6728\u9ec4",
          pinyin: "ruanmuhuang",
        },
        {
          CMYK: [6, 51, 95, 1],
          RGB: [220, 145, 35],
          hex: "#dc9123",
          name: "\u98ce\u5e06\u9ec4",
          pinyin: "fengfanhuang",
        },
        {
          CMYK: [19, 44, 75, 7],
          RGB: [192, 147, 81],
          hex: "#c09351",
          name: "\u6842\u76ae\u6de1\u68d5",
          pinyin: "guipidanzong",
        },
        {
          CMYK: [32, 40, 53, 22],
          RGB: [151, 132, 108],
          hex: "#97846c",
          name: "\u7334\u6bdb\u7070",
          pinyin: "houmaohui",
        },
        {
          CMYK: [27, 60, 97, 21],
          RGB: [152, 101, 36],
          hex: "#986524",
          name: "\u5c71\u9e21\u8910",
          pinyin: "shanjihe",
        },
        {
          CMYK: [37, 65, 84, 49],
          RGB: [102, 70, 42],
          hex: "#66462a",
          name: "\u9a7c\u8272",
          pinyin: "tuose",
        },
        {
          CMYK: [38, 69, 90, 54],
          RGB: [93, 61, 33],
          hex: "#5d3d21",
          name: "\u8336\u8910",
          pinyin: "chahe",
        },
        {
          CMYK: [37, 74, 96, 55],
          RGB: [92, 55, 25],
          hex: "#5c3719",
          name: "\u53e4\u94dc\u8910",
          pinyin: "gutonghe",
        },
        {
          CMYK: [0, 10, 14, 0],
          RGB: [251, 236, 222],
          hex: "#fbecde",
          name: "\u8377\u82b1\u767d",
          pinyin: "hehuabai",
        },
        {
          CMYK: [0, 40, 52, 0],
          RGB: [248, 179, 127],
          hex: "#f8b37f",
          name: "\u73ab\u7470\u7c89",
          pinyin: "meiguifen",
        },
        {
          CMYK: [0, 62, 88, 0],
          RGB: [249, 125, 28],
          hex: "#f97d1c",
          name: "\u6a58\u6a59",
          pinyin: "jucheng",
        },
        {
          CMYK: [0, 62, 85, 0],
          RGB: [250, 126, 35],
          hex: "#fa7e23",
          name: "\u7f8e\u4eba\u7126\u6a59",
          pinyin: "meirenjiaocheng",
        },
        {
          CMYK: [0, 28, 25, 0],
          RGB: [247, 205, 188],
          hex: "#f7cdbc",
          name: "\u6da6\u7ea2",
          pinyin: "runhong",
        },
        {
          CMYK: [0, 28, 22, 0],
          RGB: [246, 206, 193],
          hex: "#f6cec1",
          name: "\u6de1\u6843\u7ea2",
          pinyin: "dantaohong",
        },
        {
          CMYK: [0, 53, 65, 0],
          RGB: [240, 148, 93],
          hex: "#f0945d",
          name: "\u6d77\u87ba\u6a59",
          pinyin: "hailuocheng",
        },
        {
          CMYK: [0, 44, 32, 0],
          RGB: [240, 173, 160],
          hex: "#f0ada0",
          name: "\u6843\u7ea2",
          pinyin: "taohong",
        },
        {
          CMYK: [0, 45, 34, 0],
          RGB: [238, 170, 156],
          hex: "#eeaa9c",
          name: "\u988a\u7ea2",
          pinyin: "jiahong",
        },
        {
          CMYK: [0, 49, 41, 0],
          RGB: [238, 160, 140],
          hex: "#eea08c",
          name: "\u6de1\u7f42\u7c9f\u7ea2",
          pinyin: "danyingsuhong",
        },
        {
          CMYK: [0, 58, 67, 0],
          RGB: [234, 137, 88],
          hex: "#ea8958",
          name: "\u6668\u66e6\u7ea2",
          pinyin: "chenxihong",
        },
        {
          CMYK: [0, 65, 80, 0],
          RGB: [242, 118, 53],
          hex: "#f27635",
          name: "\u87f9\u58f3\u7ea2",
          pinyin: "xiekehong",
        },
        {
          CMYK: [0, 69, 86, 0],
          RGB: [248, 107, 29],
          hex: "#f86b1d",
          name: "\u91d1\u83b2\u82b1\u6a59",
          pinyin: "jinlianhuacheng",
        },
        {
          CMYK: [0, 69, 70, 0],
          RGB: [239, 111, 72],
          hex: "#ef6f48",
          name: "\u8349\u8393\u7ea2",
          pinyin: "caomeihong",
        },
        {
          CMYK: [0, 72, 82, 0],
          RGB: [239, 99, 43],
          hex: "#ef632b",
          name: "\u9f99\u775b\u9c7c\u7ea2",
          pinyin: "longjingyuhong",
        },
        {
          CMYK: [0, 81, 84, 0],
          RGB: [241, 68, 29],
          hex: "#f1441d",
          name: "\u873b\u8713\u7ea2",
          pinyin: "qingtinghong",
        },
        {
          CMYK: [0, 80, 83, 0],
          RGB: [240, 75, 34],
          hex: "#f04b22",
          name: "\u5927\u7ea2",
          pinyin: "dahong",
        },
        {
          CMYK: [0, 80, 85, 0],
          RGB: [242, 72, 27],
          hex: "#f2481b",
          name: "\u67ff\u7ea2",
          pinyin: "shihong",
        },
        {
          CMYK: [0, 80, 85, 0],
          RGB: [243, 71, 24],
          hex: "#f34718",
          name: "\u69b4\u82b1\u7ea2",
          pinyin: "liuhuahong",
        },
        {
          CMYK: [0, 83, 87, 0],
          RGB: [244, 62, 6],
          hex: "#f43e06",
          name: "\u94f6\u6731",
          pinyin: "yinzhu",
        },
        {
          CMYK: [0, 78, 83, 0],
          RGB: [237, 81, 38],
          hex: "#ed5126",
          name: "\u6731\u7ea2",
          pinyin: "zhuhong",
        },
        {
          CMYK: [0, 50, 68, 0],
          RGB: [240, 156, 90],
          hex: "#f09c5a",
          name: "\u9c91\u9c7c\u7ea2",
          pinyin: "guiyuhong",
        },
        {
          CMYK: [5, 69, 87, 0],
          RGB: [242, 123, 31],
          hex: "#f26b1f",
          name: "\u91d1\u9ec4",
          pinyin: "jinhuang",
        },
        {
          CMYK: [7, 52, 71, 1],
          RGB: [217, 145, 86],
          hex: "#d99156",
          name: "\u9e7f\u76ae\u8910",
          pinyin: "lupihe",
        },
        {
          CMYK: [4, 57, 82, 1],
          RGB: [219, 133, 64],
          hex: "#db8540",
          name: "\u9189\u74dc\u8089",
          pinyin: "zuiguarou",
        },
        {
          CMYK: [0, 64, 93, 0],
          RGB: [222, 118, 34],
          hex: "#de7622",
          name: "\u9e82\u68d5",
          pinyin: "jizong",
        },
        {
          CMYK: [22, 28, 34, 6],
          RGB: [193, 178, 163],
          hex: "#c1b2a3",
          name: "\u6de1\u94f6\u7070",
          pinyin: "danyinhui",
        },
        {
          CMYK: [18, 57, 76, 6],
          RGB: [190, 126, 74],
          hex: "#be7e4a",
          name: "\u6de1\u8d6d",
          pinyin: "danzhe",
        },
        {
          CMYK: [14, 69, 100, 4],
          RGB: [193, 101, 26],
          hex: "#c1651a",
          name: "\u69df\u6994\u7efc",
          pinyin: "binglangzong",
        },
        {
          CMYK: [34, 42, 46, 23],
          RGB: [145, 128, 114],
          hex: "#918072",
          name: "\u94f6\u7070",
          pinyin: "yinhui",
        },
        {
          CMYK: [42, 40, 46, 20],
          RGB: [154, 136, 120],
          hex: "#9a8878",
          name: "\u6d77\u9e25\u7070",
          pinyin: "haiouhui",
        },
        {
          CMYK: [27, 69, 85, 22],
          RGB: [148, 88, 51],
          hex: "#945833",
          name: "\u6de1\u5496\u5561",
          pinyin: "dankafei",
        },
        {
          CMYK: [26, 76, 97, 20],
          RGB: [150, 77, 34],
          hex: "#964d22",
          name: "\u5ca9\u77f3\u68d5",
          pinyin: "yanshizong",
        },
        {
          CMYK: [25, 80, 100, 20],
          RGB: [149, 68, 22],
          hex: "#954416",
          name: "\u8292\u679c\u68d5",
          pinyin: "mangguozong",
        },
        {
          CMYK: [39, 60, 58, 51],
          RGB: [98, 73, 65],
          hex: "#624941",
          name: "\u77f3\u677f\u7070",
          pinyin: "shibanhui",
        },
        {
          CMYK: [38, 63, 63, 50],
          RGB: [100, 72, 61],
          hex: "#64483d",
          name: "\u73e0\u6bcd\u7070",
          pinyin: "zhumuhui",
        },
        {
          CMYK: [32, 83, 96, 41],
          RGB: [113, 54, 29],
          hex: "#71361d",
          name: "\u4e01\u9999\u68d5",
          pinyin: "dingxiangzong",
        },
        {
          CMYK: [30, 87, 100, 38],
          RGB: [117, 49, 23],
          hex: "#753117",
          name: "\u5496\u5561",
          pinyin: "kafei",
        },
        {
          CMYK: [30, 89, 100, 39],
          RGB: [115, 46, 18],
          hex: "#732e12",
          name: "\u7b4d\u76ae\u68d5",
          pinyin: "sunpizong",
        },
        {
          CMYK: [0, 72, 86, 0],
          RGB: [252, 99, 21],
          hex: "#fc6315",
          name: "\u71d5\u9894\u7ea2",
          pinyin: "yanhanhong",
        },
        {
          CMYK: [3, 38, 39, 0],
          RGB: [232, 180, 154],
          hex: "#e8b49a",
          name: "\u7389\u7c89\u7ea2",
          pinyin: "yufenhong",
        },
        {
          CMYK: [0, 70, 87, 0],
          RGB: [228, 104, 40],
          hex: "#e46828",
          name: "\u91d1\u9a7c",
          pinyin: "jintuo",
        },
        {
          CMYK: [0, 76, 97, 0],
          RGB: [216, 89, 22],
          hex: "#d85916",
          name: "\u94c1\u68d5",
          pinyin: "tiezong",
        },
        {
          CMYK: [23, 35, 38, 10],
          RGB: [183, 160, 145],
          hex: "#b7a091",
          name: "\u86db\u7f51\u7070",
          pinyin: "zhuwanghui",
        },
        {
          CMYK: [16, 77, 100, 6],
          RGB: [183, 81, 29],
          hex: "#b7511d",
          name: "\u6de1\u53ef\u53ef\u68d5",
          pinyin: "dankekezong",
        },
        {
          CMYK: [31, 63, 66, 31],
          RGB: [139, 97, 77],
          hex: "#8b614d",
          name: "\u4e2d\u7ea2\u7070",
          pinyin: "zhonghonghui",
        },
        {
          CMYK: [28, 76, 82, 26],
          RGB: [140, 75, 49],
          hex: "#8c4b31",
          name: "\u6de1\u571f\u9ec4",
          pinyin: "dantuhuang",
        },
        {
          CMYK: [28, 83, 92, 28],
          RGB: [135, 61, 36],
          hex: "#873d24",
          name: "\u6de1\u8c46\u6c99",
          pinyin: "dandousha",
        },
        {
          CMYK: [27, 85, 98, 27],
          RGB: [136, 58, 30],
          hex: "#883a1e",
          name: "\u6930\u58f3\u68d5",
          pinyin: "yekezong",
        },
        {
          CMYK: [40, 64, 61, 56],
          RGB: [91, 66, 58],
          hex: "#5b423a",
          name: "\u6de1\u94c1\u7070",
          pinyin: "dantiehui",
        },
        {
          CMYK: [37, 72, 72, 52],
          RGB: [96, 61, 48],
          hex: "#603d30",
          name: "\u4e2d\u7070\u9a7c",
          pinyin: "zhonghuituo",
        },
        {
          CMYK: [34, 82, 85, 47],
          RGB: [103, 52, 36],
          hex: "#673424",
          name: "\u6de1\u6817\u68d5",
          pinyin: "danlizong",
        },
        {
          CMYK: [33, 89, 92, 48],
          RGB: [101, 43, 28],
          hex: "#652b1c",
          name: "\u53ef\u53ef\u68d5",
          pinyin: "kekezong",
        },
        {
          CMYK: [32, 90, 95, 45],
          RGB: [105, 42, 27],
          hex: "#692a1b",
          name: "\u67de\u53f6\u68d5",
          pinyin: "zhayezong",
        },
        {
          CMYK: [0, 53, 59, 0],
          RGB: [251, 153, 104],
          hex: "#fb9968",
          name: "\u91ce\u8537\u8587\u7ea2",
          pinyin: "yeqiangweihong",
        },
        {
          CMYK: [0, 65, 79, 0],
          RGB: [252, 121, 48],
          hex: "#fc7930",
          name: "\u83e0\u841d\u7ea2",
          pinyin: "boluohong",
        },
        {
          CMYK: [2, 31, 31, 0],
          RGB: [237, 195, 174],
          hex: "#edc3ae",
          name: "\u85d5\u8377",
          pinyin: "ouhe",
        },
        {
          CMYK: [0, 70, 91, 0],
          RGB: [225, 103, 35],
          hex: "#e16723",
          name: "\u9676\u74f7\u7ea2",
          pinyin: "taocihong",
        },
        {
          CMYK: [16, 23, 27, 2],
          RGB: [212, 196, 183],
          hex: "#d4c4b7",
          name: "\u6653\u7070",
          pinyin: "xiaohui",
        },
        {
          CMYK: [9, 64, 78, 1],
          RGB: [207, 117, 67],
          hex: "#cf7543",
          name: "\u4f59\u70ec\u7ea2",
          pinyin: "yujinhong",
        },
        {
          CMYK: [8, 72, 93, 1],
          RGB: [205, 98, 39],
          hex: "#cd6227",
          name: "\u706b\u7816\u7ea2",
          pinyin: "huozhuanhong",
        },
        {
          CMYK: [22, 64, 71, 12],
          RGB: [170, 106, 76],
          hex: "#aa6a4c",
          name: "\u706b\u6ce5\u68d5",
          pinyin: "huonizong",
        },
        {
          CMYK: [20, 76, 92, 9],
          RGB: [166, 82, 44],
          hex: "#a6522c",
          name: "\u7ec0\u7ea2",
          pinyin: "ganhong",
        },
        {
          CMYK: [31, 79, 77, 37],
          RGB: [119, 61, 49],
          hex: "#773d31",
          name: "\u6a61\u6811\u68d5",
          pinyin: "xiangshuzong",
        },
        {
          CMYK: [45, 68, 57, 66],
          RGB: [72, 51, 50],
          hex: "#483332",
          name: "\u6d77\u62a5\u7070",
          pinyin: "haibaohui",
        },
        {
          CMYK: [41, 76, 64, 65],
          RGB: [175, 46, 43],
          hex: "#4b2e2b",
          name: "\u73ab\u7470\u7070",
          pinyin: "meiguihui",
        },
        {
          CMYK: [41, 84, 71, 67],
          RGB: [72, 37, 34],
          hex: "#482522",
          name: "\u706b\u5c71\u68d5",
          pinyin: "huoshanzong",
        },
        {
          CMYK: [40, 92, 78, 66],
          RGB: [72, 30, 28],
          hex: "#481e1c",
          name: "\u8c46\u6c99",
          pinyin: "dousha",
        },
        {
          CMYK: [0, 9, 12, 0],
          RGB: [251, 238, 226],
          hex: "#fbeee2",
          name: "\u6de1\u7c73\u7c89",
          pinyin: "danmifen",
        },
        {
          CMYK: [0, 19, 19, 0],
          RGB: [246, 220, 206],
          hex: "#f6dcce",
          name: "\u521d\u6843\u7c89\u7ea2",
          pinyin: "chutaofenhong",
        },
        {
          CMYK: [0, 27, 27, 0],
          RGB: [247, 207, 186],
          hex: "#f7cfba",
          name: "\u4ecb\u58f3\u6de1\u7c89\u7ea2",
          pinyin: "jieqiaodanfenhong",
        },
        {
          CMYK: [0, 43, 43, 0],
          RGB: [246, 173, 143],
          hex: "#f6ad8f",
          name: "\u6de1\u85cf\u82b1\u7ea2",
          pinyin: "dancanghuahong",
        },
        {
          CMYK: [0, 58, 61, 0],
          RGB: [246, 140, 96],
          hex: "#f68c60",
          name: "\u74dc\u74e4\u7ea2",
          pinyin: "guaranghong",
        },
        {
          CMYK: [0, 67, 74, 0],
          RGB: [249, 114, 61],
          hex: "#f9723d",
          name: "\u8299\u84c9\u7ea2",
          pinyin: "furonghong",
        },
        {
          CMYK: [0, 74, 85, 0],
          RGB: [250, 93, 25],
          hex: "#fa5d19",
          name: "\u8393\u9171\u7ea2",
          pinyin: "meijianghong",
        },
        {
          CMYK: [0, 62, 66, 0],
          RGB: [238, 128, 85],
          hex: "#ee8055",
          name: "\u6cd5\u87ba\u7ea2",
          pinyin: "faluohong",
        },
        {
          CMYK: [4, 82, 99, 0],
          RGB: [207, 72, 19],
          hex: "#cf4813",
          name: "\u843d\u971e\u7ea2",
          pinyin: "luoxiahong",
        },
        {
          CMYK: [21, 43, 43, 9],
          RGB: [184, 148, 133],
          hex: "#b89485",
          name: "\u6de1\u73ab\u7470\u7070",
          pinyin: "danmeiguihui",
        },
        {
          CMYK: [18, 80, 92, 7],
          RGB: [177, 75, 40],
          hex: "#b14b28",
          name: "\u87f9\u8765\u7ea2",
          pinyin: "xiemaohong",
        },
        {
          CMYK: [27, 91, 95, 28],
          RGB: [134, 48, 32],
          hex: "#863020",
          name: "\u706b\u5ca9\u68d5",
          pinyin: "huoyanzong",
        },
        {
          CMYK: [27, 96, 100, 27],
          RGB: [134, 38, 23],
          hex: "#862617",
          name: "\u8d6d\u77f3",
          pinyin: "zheshi",
        },
        {
          CMYK: [36, 90, 82, 56],
          RGB: [89, 38, 32],
          hex: "#592620",
          name: "\u6697\u9a7c\u68d5",
          pinyin: "antuozong",
        },
        {
          CMYK: [35, 96, 88, 55],
          RGB: [90, 31, 27],
          hex: "#5a1f1b",
          name: "\u9171\u68d5",
          pinyin: "jiangzong",
        },
        {
          CMYK: [34, 98, 91, 53],
          RGB: [92, 30, 25],
          hex: "#5c1e19",
          name: "\u6817\u68d5",
          pinyin: "lizong",
        },
        {
          CMYK: [0, 31, 24, 0],
          RGB: [244, 199, 186],
          hex: "#f4c7ba",
          name: "\u6d0b\u6c34\u4ed9\u7ea2",
          pinyin: "yangshuixianhong",
        },
        {
          CMYK: [0, 67, 55, 0],
          RGB: [241, 118, 102],
          hex: "#f17666",
          name: "\u8c37\u9798\u7ea2",
          pinyin: "guqiaohong",
        },
        {
          CMYK: [0, 77, 69, 0],
          RGB: [241, 86, 66],
          hex: "#f15642",
          name: "\u82f9\u679c\u7ea2",
          pinyin: "pingguohong",
        },
        {
          CMYK: [0, 84, 82, 0],
          RGB: [245, 57, 28],
          hex: "#f5391c",
          name: "\u94c1\u6c34\u7ea2",
          pinyin: "tieshuihong",
        },
        {
          CMYK: [0, 76, 67, 0],
          RGB: [242, 90, 71],
          hex: "#f25a47",
          name: "\u6842\u7ea2",
          pinyin: "guihong",
        },
        {
          CMYK: [0, 84, 82, 0],
          RGB: [243, 59, 31],
          hex: "#f33b1f",
          name: "\u6781\u5149\u7ea2",
          pinyin: "jiguanghong",
        },
        {
          CMYK: [0, 38, 25, 0],
          RGB: [242, 185, 178],
          hex: "#f2b9b2",
          name: "\u7c89\u7ea2",
          pinyin: "fenhong",
        },
        {
          CMYK: [0, 53, 36, 0],
          RGB: [241, 151, 144],
          hex: "#f19790",
          name: "\u820c\u7ea2",
          pinyin: "shehong",
        },
        {
          CMYK: [0, 76, 68, 0],
          RGB: [240, 90, 70],
          hex: "#f05a46",
          name: "\u66f2\u7ea2",
          pinyin: "quhong",
        },
        {
          CMYK: [0, 83, 81, 0],
          RGB: [242, 62, 35],
          hex: "#f23e23",
          name: "\u7ea2\u6c5e\u7ea2",
          pinyin: "honggonghong",
        },
        {
          CMYK: [0, 29, 16, 0],
          RGB: [242, 202, 201],
          hex: "#f2cac9",
          name: "\u6de1\u7eef",
          pinyin: "danfei",
        },
        {
          CMYK: [0, 43, 24, 0],
          RGB: [239, 175, 173],
          hex: "#efafad",
          name: "\u65e0\u82b1\u679c\u7ea2",
          pinyin: "wuhuaguohong",
        },
        {
          CMYK: [0, 57, 36, 0],
          RGB: [241, 144, 140],
          hex: "#f1908c",
          name: "\u69b4\u5b50\u7ea2",
          pinyin: "liuzihong",
        },
        {
          CMYK: [0, 83, 81, 0],
          RGB: [240, 63, 36],
          hex: "#f03f24",
          name: "\u80ed\u8102\u7ea2",
          pinyin: "yanzhihong",
        },
        {
          CMYK: [0, 50, 23, 0],
          RGB: [240, 161, 168],
          hex: "#f0a1a8",
          name: "\u5408\u6b22\u7ea2",
          pinyin: "hehuanhong",
        },
        {
          CMYK: [0, 56, 27, 0],
          RGB: [241, 147, 156],
          hex: "#f1939c",
          name: "\u6625\u6885\u7ea2",
          pinyin: "chunmeihong",
        },
        {
          CMYK: [0, 65, 38, 0],
          RGB: [240, 124, 130],
          hex: "#f07c82",
          name: "\u9999\u53f6\u7ea2",
          pinyin: "xiangyehong",
        },
        {
          CMYK: [0, 80, 72, 0],
          RGB: [240, 74, 58],
          hex: "#f04a3a",
          name: "\u73ca\u745a\u7ea2",
          pinyin: "shanhuhong",
        },
        {
          CMYK: [0, 84, 82, 0],
          RGB: [241, 60, 34],
          hex: "#f13c22",
          name: "\u841d\u535c\u7ea2",
          pinyin: "luobohong",
        },
        {
          CMYK: [0, 65, 29, 0],
          RGB: [231, 124, 142],
          hex: "#e77c8e",
          name: "\u6de1\u831c\u7ea2",
          pinyin: "danqianhong",
        },
        {
          CMYK: [0, 77, 49, 0],
          RGB: [237, 90, 101],
          hex: "#ed5a65",
          name: "\u8273\u7ea2",
          pinyin: "yanhong",
        },
        {
          CMYK: [0, 81, 66, 0],
          RGB: [237, 72, 69],
          hex: "#ed4845",
          name: "\u6de1\u83fd\u7ea2",
          pinyin: "danshuhong",
        },
        {
          CMYK: [0, 84, 76, 0],
          RGB: [237, 59, 47],
          hex: "#ed3b2f",
          name: "\u9c7c\u9cc3\u7ea2",
          pinyin: "yusaihong",
        },
        {
          CMYK: [0, 86, 82, 0],
          RGB: [237, 51, 33],
          hex: "#ed3321",
          name: "\u6a31\u6843\u7ea2",
          pinyin: "yingtaohong",
        },
        {
          CMYK: [0, 82, 44, 0],
          RGB: [238, 72, 102],
          hex: "#ee4866",
          name: "\u6de1\u854a\u9999\u7ea2",
          pinyin: "danruixianghong",
        },
        {
          CMYK: [0, 82, 46, 0],
          RGB: [238, 72, 99],
          hex: "#ee4863",
          name: "\u77f3\u7af9\u7ea2",
          pinyin: "shizhuhong",
        },
        {
          CMYK: [0, 82, 50, 0],
          RGB: [239, 71, 93],
          hex: "#ef475d",
          name: "\u8349\u8309\u8389\u7ea2",
          pinyin: "caomolihong",
        },
        {
          CMYK: [0, 84, 60, 0],
          RGB: [238, 63, 77],
          hex: "#ee3f4d",
          name: "\u8336\u82b1\u7ea2",
          pinyin: "chahuahong",
        },
        {
          CMYK: [0, 86, 74, 0],
          RGB: [237, 51, 51],
          hex: "#ed3333",
          name: "\u67b8\u67a2\u7ea2",
          pinyin: "goushuhong",
        },
        {
          CMYK: [0, 88, 81, 0],
          RGB: [236, 43, 36],
          hex: "#ec2b24",
          name: "\u79cb\u6d77\u68e0\u7ea2",
          pinyin: "qiuhaitanghong",
        },
        {
          CMYK: [0, 89, 84, 0],
          RGB: [235, 38, 26],
          hex: "#eb261a",
          name: "\u4e3d\u6625\u7ea2",
          pinyin: "lichunhong",
        },
        {
          CMYK: [0, 90, 90, 0],
          RGB: [222, 42, 24],
          hex: "#de2a18",
          name: "\u5915\u9633\u7ea2",
          pinyin: "xiyanghong",
        },
        {
          CMYK: [0, 92, 95, 0],
          RGB: [212, 37, 23],
          hex: "#d42517",
          name: "\u9e64\u9876\u7ea2",
          pinyin: "hedinghong",
        },
        {
          CMYK: [19, 89, 85, 9],
          RGB: [171, 55, 47],
          hex: "#ab372f",
          name: "\u9e45\u8840\u77f3\u7ea2",
          pinyin: "exueshihong",
        },
        {
          CMYK: [17, 98, 100, 8],
          RGB: [172, 31, 24],
          hex: "#ac1f18",
          name: "\u8986\u76c6\u5b50\u7ea2",
          pinyin: "fupenzihong",
        },
        {
          CMYK: [36, 81, 64, 54],
          RGB: [93, 49, 49],
          hex: "#5d3131",
          name: "\u8c82\u7d2b",
          pinyin: "diaozi",
        },
        {
          CMYK: [35, 94, 77, 53],
          RGB: [92, 34, 35],
          hex: "#5c2223",
          name: "\u6697\u7389\u7d2b",
          pinyin: "anyuzi",
        },
        {
          CMYK: [35, 100, 85, 54],
          RGB: [90, 25, 27],
          hex: "#5a191b",
          name: "\u6817\u7d2b",
          pinyin: "lizi",
        },
        {
          CMYK: [35, 100, 80, 54],
          RGB: [90, 18, 22],
          hex: "#5a1216",
          name: "\u8461\u8404\u9171\u7d2b",
          pinyin: "putaojiangzi",
        },
        {
          CMYK: [0, 49, 27, 0],
          RGB: [238, 162, 164],
          hex: "#eea2a4",
          name: "\u7261\u4e39\u7c89\u7ea2",
          pinyin: "mudanfenhong",
        },
        {
          CMYK: [0, 78, 44, 0],
          RGB: [237, 85, 106],
          hex: "#ed556a",
          name: "\u5c71\u8336\u7ea2",
          pinyin: "shanchahong",
        },
        {
          CMYK: [0, 86, 55, 0],
          RGB: [240, 55, 82],
          hex: "#f03752",
          name: "\u6d77\u68e0\u7ea2",
          pinyin: "haitanghong",
        },
        {
          CMYK: [13, 83, 62, 3],
          RGB: [192, 72, 81],
          hex: "#c04851",
          name: "\u7389\u7ea2",
          pinyin: "yuhong",
        },
        {
          CMYK: [11, 93, 77, 2],
          RGB: [192, 44, 56],
          hex: "#c02c38",
          name: "\u9ad8\u7cb1\u7ea2",
          pinyin: "gaolianghong",
        },
        {
          CMYK: [22, 76, 54, 12],
          RGB: [167, 83, 90],
          hex: "#a7535a",
          name: "\u6ee1\u6c5f\u7ea2",
          pinyin: "manjianghong",
        },
        {
          CMYK: [28, 100, 86, 33],
          RGB: [124, 24, 35],
          hex: "#7c1823",
          name: "\u67a3\u7ea2",
          pinyin: "zaohong",
        },
        {
          CMYK: [39, 92, 67, 64],
          RGB: [76, 31, 36],
          hex: "#4c1f24",
          name: "\u8461\u8404\u7d2b",
          pinyin: "putaozi",
        },
        {
          CMYK: [39, 100, 79, 63],
          RGB: [77, 16, 24],
          hex: "#4d1018",
          name: "\u9171\u7d2b",
          pinyin: "jiangzi",
        },
        {
          CMYK: [0, 89, 62, 0],
          RGB: [238, 39, 70],
          hex: "#ee2746",
          name: "\u6de1\u66d9\u7ea2",
          pinyin: "danshuhong",
        },
        {
          CMYK: [0, 93, 76, 0],
          RGB: [222, 28, 49],
          hex: "#de1c31",
          name: "\u5510\u83d6\u84b2\u7ea2",
          pinyin: "tangchangpuhong",
        },
        {
          CMYK: [1, 95, 82, 0],
          RGB: [209, 26, 45],
          hex: "#d11a2d",
          name: "\u9e45\u51a0\u7ea2",
          pinyin: "eguanhong",
        },
        {
          CMYK: [13, 76, 50, 2],
          RGB: [196, 90, 101],
          hex: "#c45a65",
          name: "\u8393\u7ea2",
          pinyin: "meihong",
        },
        {
          CMYK: [10, 96, 82, 2],
          RGB: [194, 31, 48],
          hex: "#c21f30",
          name: "\u67ab\u53f6\u7ea2",
          pinyin: "fengyehong",
        },
        {
          CMYK: [19, 99, 86, 11],
          RGB: [166, 27, 41],
          hex: "#a61b29",
          name: "\u82cb\u83dc\u7ea2",
          pinyin: "xiancaihong",
        },
        {
          CMYK: [29, 73, 51, 28],
          RGB: [137, 78, 84],
          hex: "#894e54",
          name: "\u70df\u7ea2",
          pinyin: "yanhong",
        },
        {
          CMYK: [27, 98, 79, 30],
          RGB: [130, 32, 43],
          hex: "#82202b",
          name: "\u6697\u7d2b\u82d1\u7ea2",
          pinyin: "anziyuanhong",
        },
        {
          CMYK: [27, 100, 90, 29],
          RGB: [130, 17, 31],
          hex: "#82111f",
          name: "\u6bb7\u7ea2",
          pinyin: "yanhong",
        },
        {
          CMYK: [36, 95, 71, 59],
          RGB: [84, 30, 36],
          hex: "#541e24",
          name: "\u732a\u809d\u7d2b",
          pinyin: "zhuganzi",
        },
        {
          CMYK: [38, 100, 81, 61],
          RGB: [80, 10, 22],
          hex: "#500a16",
          name: "\u91d1\u9c7c\u7d2b",
          pinyin: "jinyuzi",
        },
        {
          CMYK: [1, 11, 9, 0],
          RGB: [248, 235, 230],
          hex: "#f8ebe6",
          name: "\u8349\u73e0\u7ea2",
          pinyin: "caozhuhong",
        },
        {
          CMYK: [0, 68, 21, 0],
          RGB: [236, 118, 150],
          hex: "#ec7696",
          name: "\u6de1\u7edb\u7ea2",
          pinyin: "danjianghong",
        },
        {
          CMYK: [0, 86, 30, 0],
          RGB: [239, 52, 115],
          hex: "#ef3473",
          name: "\u54c1\u7ea2",
          pinyin: "pinhong",
        },
        {
          CMYK: [0, 69, 22, 0],
          RGB: [234, 114, 147],
          hex: "#ea7293",
          name: "\u51e4\u4ed9\u82b1\u7ea2",
          pinyin: "fengxianhuahong",
        },
        {
          CMYK: [0, 52, 18, 0],
          RGB: [236, 155, 173],
          hex: "#ec9bad",
          name: "\u7c89\u56e2\u82b1\u7ea2",
          pinyin: "fentuanhuahong",
        },
        {
          CMYK: [0, 80, 28, 0],
          RGB: [235, 80, 126],
          hex: "#eb507e",
          name: "\u5939\u7af9\u6843\u7ea2",
          pinyin: "jiazhutaohong",
        },
        {
          CMYK: [0, 88, 36, 0],
          RGB: [237, 47, 106],
          hex: "#ed2f6a",
          name: "\u69b2\u6872\u7ea2",
          pinyin: "wenpohong",
        },
        {
          CMYK: [0, 39, 14, 0],
          RGB: [238, 184, 195],
          hex: "#eeb8c3",
          name: "\u59dc\u7ea2",
          pinyin: "jianghong",
        },
        {
          CMYK: [0, 80, 27, 0],
          RGB: [234, 81, 127],
          hex: "#ea517f",
          name: "\u83b2\u74e3\u7ea2",
          pinyin: "lianbanhong",
        },
        {
          CMYK: [0, 33, 11, 0],
          RGB: [241, 196, 205],
          hex: "#f1c4cd",
          name: "\u6c34\u7ea2",
          pinyin: "shuihong",
        },
        {
          CMYK: [0, 60, 18, 0],
          RGB: [236, 138, 164],
          hex: "#ec8aa4",
          name: "\u62a5\u6625\u7ea2",
          pinyin: "baochunhong",
        },
        {
          CMYK: [8, 78, 35, 1],
          RGB: [206, 87, 109],
          hex: "#ce5777",
          name: "\u6708\u5b63\u7ea2",
          pinyin: "yuejihong",
        },
        {
          CMYK: [0, 52, 15, 0],
          RGB: [237, 157, 178],
          hex: "#ed9db2",
          name: "\u8c47\u8c46\u7ea2",
          pinyin: "jiangdouhong",
        },
        {
          CMYK: [0, 63, 18, 0],
          RGB: [239, 130, 160],
          hex: "#ef82a0",
          name: "\u971e\u5149\u7ea2",
          pinyin: "xiaguanghong",
        },
        {
          CMYK: [0, 85, 33, 0],
          RGB: [235, 60, 112],
          hex: "#eb3c70",
          name: "\u677e\u53f6\u7261\u4e39\u7ea2",
          pinyin: "songyemudanhong",
        },
        {
          CMYK: [0, 88, 40, 0],
          RGB: [236, 44, 100],
          hex: "#ec2c64",
          name: "\u559c\u86cb\u7ea2",
          pinyin: "xidanhong",
        },
        {
          CMYK: [5, 38, 20, 0],
          RGB: [227, 180, 184],
          hex: "#e3b4b8",
          name: "\u9f20\u9f3b\u7ea2",
          pinyin: "shubihong",
        },
        {
          CMYK: [5, 96, 73, 1],
          RGB: [204, 22, 58],
          hex: "#cc163a",
          name: "\u5c16\u6676\u7389\u7ea2",
          pinyin: "jianjingyuhong",
        },
        {
          CMYK: [16, 61, 34, 4],
          RGB: [194, 124, 136],
          hex: "#c27c88",
          name: "\u5c71\u9ece\u8c46\u7ea2",
          pinyin: "shanlidouhong",
        },
        {
          CMYK: [13, 90, 56, 3],
          RGB: [191, 53, 83],
          hex: "#bf3553",
          name: "\u9526\u8475\u7ea2",
          pinyin: "jinkuihong",
        },
        {
          CMYK: [41, 64, 44, 36],
          RGB: [115, 87, 92],
          hex: "#73575c",
          name: "\u9f20\u80cc\u7070",
          pinyin: "shubeihui",
        },
        {
          CMYK: [33, 100, 75, 49],
          RGB: [98, 22, 36],
          hex: "#621624",
          name: "\u7518\u8517\u7d2b",
          pinyin: "ganzhezi",
        },
        {
          CMYK: [32, 100, 84, 49],
          RGB: [99, 7, 28],
          hex: "#63071c",
          name: "\u77f3\u7af9\u7d2b",
          pinyin: "shizhuzi",
        },
        {
          CMYK: [57, 72, 54, 74],
          RGB: [54, 40, 43],
          hex: "#36282b",
          name: "\u82cd\u8747\u7070",
          pinyin: "cangyinghui",
        },
        {
          CMYK: [52, 88, 58, 81],
          RGB: [48, 22, 28],
          hex: "#30161c",
          name: "\u5375\u77f3\u7d2b",
          pinyin: "luanshizi",
        },
        {
          CMYK: [56, 88, 62, 84],
          RGB: [43, 18, 22],
          hex: "#2b1216",
          name: "\u674e\u7d2b",
          pinyin: "lizi",
        },
        {
          CMYK: [58, 90, 63, 83],
          RGB: [45, 12, 19],
          hex: "#2d0c13",
          name: "\u8304\u76ae\u7d2b",
          pinyin: "qiepizi",
        },
        {
          CMYK: [0, 76, 16, 0],
          RGB: [206, 94, 138],
          hex: "#ce5e8a",
          name: "\u540a\u949f\u82b1\u7ea2",
          pinyin: "diaozhonghuahong",
        },
        {
          CMYK: [0, 81, 18, 0],
          RGB: [236, 78, 138],
          hex: "#ec4e8a",
          name: "\u5154\u773c\u7ea2",
          pinyin: "tuyanhong",
        },
        {
          CMYK: [0, 87, 24, 0],
          RGB: [238, 44, 121],
          hex: "#ee2c79",
          name: "\u7d2b\u8346\u7ea2",
          pinyin: "zijinghong",
        },
        {
          CMYK: [24, 99, 52, 19],
          RGB: [149, 28, 72],
          hex: "#951c48",
          name: "\u83dc\u5934\u7d2b",
          pinyin: "caitouzi",
        },
        {
          CMYK: [34, 97, 54, 50],
          RGB: [98, 29, 52],
          hex: "#621d34",
          name: "\u9e5e\u51a0\u7d2b",
          pinyin: "yaoguanzi",
        },
        {
          CMYK: [33, 100, 58, 60],
          RGB: [98, 16, 46],
          hex: "#62102e",
          name: "\u8461\u8404\u9152\u7ea2",
          pinyin: "putaojiuhong",
        },
        {
          CMYK: [53, 81, 50, 74],
          RGB: [56, 33, 41],
          hex: "#382129",
          name: "\u78e8\u77f3\u7d2b",
          pinyin: "moshizi",
        },
        {
          CMYK: [48, 90, 50, 76],
          RGB: [56, 25, 36],
          hex: "#381924",
          name: "\u6a80\u7d2b",
          pinyin: "tanzi",
        },
        {
          CMYK: [50, 91, 54, 79],
          RGB: [51, 20, 30],
          hex: "#33141e",
          name: "\u706b\u9e45\u7d2b",
          pinyin: "huoezi",
        },
        {
          CMYK: [53, 92, 55, 81],
          RGB: [49, 15, 27],
          hex: "#310f1b",
          name: "\u58a8\u7d2b",
          pinyin: "mozi",
        },
        {
          CMYK: [0, 48, 15, 0],
          RGB: [238, 166, 183],
          hex: "#eea6b7",
          name: "\u6676\u7ea2",
          pinyin: "jinghong",
        },
        {
          CMYK: [0, 82, 16, 0],
          RGB: [239, 73, 139],
          hex: "#ef498b",
          name: "\u6241\u8c46\u82b1\u7ea2",
          pinyin: "biandouhuahong",
        },
        {
          CMYK: [2, 66, 22, 0],
          RGB: [222, 120, 151],
          hex: "#de7897",
          name: "\u767d\u82a8\u7ea2",
          pinyin: "baijihong",
        },
        {
          CMYK: [0, 85, 24, 0],
          RGB: [222, 63, 124],
          hex: "#de3f7c",
          name: "\u5ae9\u83f1\u7ea2",
          pinyin: "nenlinghong",
        },
        {
          CMYK: [5, 87, 30, 1],
          RGB: [209, 60, 116],
          hex: "#d13c74",
          name: "\u83e0\u6839\u7ea2",
          pinyin: "bogenhong",
        },
        {
          CMYK: [15, 68, 28, 2],
          RGB: [197, 112, 139],
          hex: "#c5708b",
          name: "\u9162\u9171\u8349\u7ea2",
          pinyin: "cujiangcaohong",
        },
        {
          CMYK: [22, 83, 34, 11],
          RGB: [168, 69, 107],
          hex: "#a8456b",
          name: "\u6d0b\u8471\u7d2b",
          pinyin: "yangcongzi",
        },
        {
          CMYK: [40, 92, 47, 64],
          RGB: [75, 30, 47],
          hex: "#4b1e2f",
          name: "\u6d77\u8c61\u7d2b",
          pinyin: "haixiangzi",
        },
        {
          CMYK: [41, 97, 49, 68],
          RGB: [70, 22, 41],
          hex: "#461629",
          name: "\u7ec0\u7d2b",
          pinyin: "ganzi",
        },
        {
          CMYK: [42, 99, 51, 69],
          RGB: [68, 14, 37],
          hex: "#440e25",
          name: "\u53e4\u94dc\u7d2b",
          pinyin: "gutongzi",
        },
        {
          CMYK: [0, 30, 12, 0],
          RGB: [240, 201, 207],
          hex: "#f0c9cf",
          name: "\u77f3\u854a\u7ea2",
          pinyin: "shiruihong",
        },
        {
          CMYK: [0, 50, 16, 0],
          RGB: [235, 160, 179],
          hex: "#eba0b3",
          name: "\u828d\u836f\u8015\u7ea2",
          pinyin: "shaoyaogenghong",
        },
        {
          CMYK: [0, 88, 23, 0],
          RGB: [236, 45, 122],
          hex: "#ec2d7a",
          name: "\u85cf\u82b1\u7ea2",
          pinyin: "canghuahong",
        },
        {
          CMYK: [0, 71, 18, 0],
          RGB: [225, 108, 150],
          hex: "#e16c96",
          name: "\u521d\u8377\u7ea2",
          pinyin: "chuhehong",
        },
        {
          CMYK: [6, 13, 7, 0],
          RGB: [237, 227, 231],
          hex: "#ede3e7",
          name: "\u9a6c\u97ad\u8349\u7d2b",
          pinyin: "mabiancaozi",
        },
        {
          CMYK: [7, 20, 8, 0],
          RGB: [233, 215, 223],
          hex: "#e9d7df",
          name: "\u4e01\u9999\u6de1\u7d2b",
          pinyin: "dingxiangdanzi",
        },
        {
          CMYK: [7, 79, 18, 0],
          RGB: [210, 86, 140],
          hex: "#d2568c",
          name: "\u4e39\u7d2b\u7ea2",
          pinyin: "danzihong",
        },
        {
          CMYK: [4, 89, 21, 0],
          RGB: [210, 53, 125],
          hex: "#d2357d",
          name: "\u73ab\u7470\u7ea2",
          pinyin: "meiguihong",
        },
        {
          CMYK: [19, 27, 9, 0],
          RGB: [209, 194, 211],
          hex: "#d1c2d3",
          name: "\u6de1\u7275\u725b\u7d2b",
          pinyin: "danqianniuzi",
        },
        {
          CMYK: [21, 37, 12, 0],
          RGB: [200, 173, 196],
          hex: "#c8adc4",
          name: "\u51e4\u4fe1\u7d2b",
          pinyin: "fengxinzi",
        },
        {
          CMYK: [23, 53, 14, 1],
          RGB: [192, 142, 175],
          hex: "#c08eaf",
          name: "\u841d\u5170\u7d2b",
          pinyin: "luolanzi",
        },
        {
          CMYK: [18, 91, 18, 2],
          RGB: [186, 47, 123],
          hex: "#ba2f7b",
          name: "\u73ab\u7470\u7d2b",
          pinyin: "meiguizi",
        },
        {
          CMYK: [58, 56, 17, 2],
          RGB: [128, 118, 163],
          hex: "#8076a3",
          name: "\u85e4\u841d\u7d2b",
          pinyin: "tengluozi",
        },
        {
          CMYK: [57, 62, 16, 2],
          RGB: [128, 109, 158],
          hex: "#806d9e",
          name: "\u69ff\u7d2b",
          pinyin: "jinzi",
        },
        {
          CMYK: [56, 72, 15, 1],
          RGB: [129, 92, 148],
          hex: "#815c94",
          name: "\u8548\u7d2b",
          pinyin: "xunzi",
        },
        {
          CMYK: [54, 89, 12, 1],
          RGB: [129, 60, 133],
          hex: "#813c85",
          name: "\u6854\u6897\u7d2b",
          pinyin: "jiegengzi",
        },
        {
          CMYK: [52, 100, 17, 7],
          RGB: [126, 22, 113],
          hex: "#7e1671",
          name: "\u9b4f\u7d2b",
          pinyin: "weizi",
        },
        {
          CMYK: [3, 26, 10, 0],
          RGB: [233, 204, 211],
          hex: "#e9ccd3",
          name: "\u829d\u5170\u7d2b",
          pinyin: "zhilanzi",
        },
        {
          CMYK: [10, 67, 12, 0],
          RGB: [210, 118, 163],
          hex: "#d276a3",
          name: "\u83f1\u9530\u7ea2",
          pinyin: "lingmenghong",
        },
        {
          CMYK: [11, 97, 10, 0],
          RGB: [204, 85, 149],
          hex: "#cc5595",
          name: "\u9f99\u987b\u7ea2",
          pinyin: "longxuhong",
        },
        {
          CMYK: [8, 22, 12, 0],
          RGB: [230, 210, 213],
          hex: "#e6d2d5",
          name: "\u84df\u7c89\u7ea2",
          pinyin: "jifenhong",
        },
        {
          CMYK: [16, 79, 14, 1],
          RGB: [195, 86, 145],
          hex: "#c35691",
          name: "\u7535\u6c14\u77f3\u7ea2",
          pinyin: "dianqishihong",
        },
        {
          CMYK: [19, 68, 18, 1],
          RGB: [192, 111, 152],
          hex: "#c06f98",
          name: "\u6a31\u8349\u7d2b",
          pinyin: "yingcaozi",
        },
        {
          CMYK: [25, 31, 26, 5],
          RGB: [189, 174, 173],
          hex: "#bdaead",
          name: "\u82a6\u7a57\u7070",
          pinyin: "lusuihui",
        },
        {
          CMYK: [26, 43, 26, 6],
          RGB: [181, 152, 161],
          hex: "#b598a1",
          name: "\u9690\u7ea2\u7070",
          pinyin: "yinhonghui",
        },
        {
          CMYK: [27, 97, 27, 14],
          RGB: [155, 30, 100],
          hex: "#9b1e64",
          name: "\u82cb\u83dc\u7d2b",
          pinyin: "xiancaizi",
        },
        {
          CMYK: [39, 53, 38, 25],
          RGB: [133, 109, 114],
          hex: "#856d72",
          name: "\u82a6\u7070",
          pinyin: "luhui",
        },
        {
          CMYK: [49, 71, 49, 58],
          RGB: [79, 56, 62],
          hex: "#4f383e",
          name: "\u66ae\u4e91\u7070",
          pinyin: "muyunhui",
        },
        {
          CMYK: [49, 82, 46, 63],
          RGB: [72, 41, 54],
          hex: "#482936",
          name: "\u6591\u9e20\u7070",
          pinyin: "banjiuhui",
        },
        {
          CMYK: [4, 11, 9, 0],
          RGB: [242, 231, 229],
          hex: "#f2e7e5",
          name: "\u6de1\u85e4\u841d\u7d2b",
          pinyin: "dantengluozi",
        },
        {
          CMYK: [10, 27, 11, 0],
          RGB: [224, 200, 209],
          hex: "#e0c8d1",
          name: "\u6de1\u9752\u7d2b",
          pinyin: "danqingzi",
        },
        {
          CMYK: [24, 58, 15, 1],
          RGB: [188, 132, 168],
          hex: "#bc84a8",
          name: "\u9752\u86e4\u58f3\u7d2b",
          pinyin: "qinghakezi",
        },
        {
          CMYK: [31, 71, 15, 1],
          RGB: [173, 101, 152],
          hex: "#ad6598",
          name: "\u8c46\u853b\u7d2b",
          pinyin: "doukouzi",
        },
        {
          CMYK: [35, 75, 18, 2],
          RGB: [163, 92, 143],
          hex: "#a35c8f",
          name: "\u6241\u8c46\u7d2b",
          pinyin: "biandouzi",
        },
        {
          CMYK: [39, 91, 15, 3],
          RGB: [152, 54, 128],
          hex: "#983680",
          name: "\u82a5\u82b1\u7d2b",
          pinyin: "jiehuazi",
        },
        {
          CMYK: [43, 97, 19, 8],
          RGB: [139, 38, 113],
          hex: "#8b2671",
          name: "\u9752\u83b2",
          pinyin: "qinglian",
        },
        {
          CMYK: [44, 85, 24, 10],
          RGB: [137, 66, 118],
          hex: "#894276",
          name: "\u8293\u7d2b",
          pinyin: "zizi",
        },
        {
          CMYK: [45, 99, 24, 16],
          RGB: [126, 32, 101],
          hex: "#7e2065",
          name: "\u845b\u5dfe\u7d2b",
          pinyin: "gejinzi",
        },
        {
          CMYK: [49, 100, 29, 32],
          RGB: [104, 23, 82],
          hex: "#681752",
          name: "\u7275\u725b\u7d2b",
          pinyin: "qianniuzi",
        },
        {
          CMYK: [53, 75, 41, 41],
          RGB: [93, 63, 81],
          hex: "#5d3f51",
          name: "\u7d2b\u7070",
          pinyin: "zihui",
        },
        {
          CMYK: [55, 87, 41, 52],
          RGB: [78, 42, 64],
          hex: "#4e2a40",
          name: "\u9f99\u775b\u9c7c\u7d2b",
          pinyin: "longjingyuzi",
        },
        {
          CMYK: [58, 96, 40, 61],
          RGB: [65, 28, 53],
          hex: "#411c35",
          name: "\u8378\u8360\u7d2b",
          pinyin: "biqizi",
        },
        {
          CMYK: [63, 74, 52, 70],
          RGB: [54, 41, 47],
          hex: "#36292f",
          name: "\u53e4\u9f0e\u7070",
          pinyin: "gudinghui",
        },
        {
          CMYK: [81, 87, 54, 84],
          RGB: [30, 19, 29],
          hex: "#1e131d",
          name: "\u4e4c\u6885\u7d2b",
          pinyin: "wumeizi",
        },
        {
          CMYK: [83, 87, 55, 86],
          RGB: [28, 13, 26],
          hex: "#1c0d1a",
          name: "\u6df1\u7275\u725b\u7d2b",
          pinyin: "shenqianniuzi",
        },
        {
          CMYK: [7, 5, 7, 0],
          RGB: [241, 240, 237],
          hex: "#f1f0ed",
          name: "\u94f6\u767d",
          pinyin: "yinbai",
        },
        {
          CMYK: [13, 10, 9, 0],
          RGB: [226, 225, 228],
          hex: "#e2e1e4",
          name: "\u82a1\u98df\u767d",
          pinyin: "qianshibai",
        },
        {
          CMYK: [23, 18, 12, 1],
          RGB: [204, 204, 214],
          hex: "#ccccd6",
          name: "\u8fdc\u5c71\u7d2b",
          pinyin: "yuanshanzi",
        },
        {
          CMYK: [39, 31, 17, 2],
          RGB: [167, 168, 189],
          hex: "#a7a8bd",
          name: "\u6de1\u84dd\u7d2b",
          pinyin: "danlanzi",
        },
        {
          CMYK: [74, 64, 14, 1],
          RGB: [97, 100, 159],
          hex: "#61649f",
          name: "\u5c71\u6897\u7d2b",
          pinyin: "shangengzi",
        },
        {
          CMYK: [63, 53, 22, 5],
          RGB: [116, 117, 155],
          hex: "#74759b",
          name: "\u87ba\u7538\u7d2b",
          pinyin: "luodianzi",
        },
        {
          CMYK: [21, 17, 19, 1],
          RGB: [207, 204, 201],
          hex: "#cfccc9",
          name: "\u739b\u7459\u7070",
          pinyin: "manaohui",
        },
        {
          CMYK: [80, 73, 21, 6],
          RGB: [82, 82, 136],
          hex: "#525288",
          name: "\u91ce\u83ca\u7d2b",
          pinyin: "yejuzi",
        },
        {
          CMYK: [100, 93, 21, 5],
          RGB: [46, 49, 124],
          hex: "#2e317c",
          name: "\u6ee1\u5929\u661f\u7d2b",
          pinyin: "mantianxingzi",
        },
        {
          CMYK: [48, 45, 40, 26],
          RGB: [122, 115, 116],
          hex: "#7a7374",
          name: "\u950c\u7070",
          pinyin: "xinhui",
        },
        {
          CMYK: [91, 84, 40, 43],
          RGB: [48, 47, 75],
          hex: "#302f4b",
          name: "\u91ce\u8461\u8404\u7d2b",
          pinyin: "yeputaozi",
        },
        {
          CMYK: [70, 69, 49, 56],
          RGB: [62, 56, 65],
          hex: "#3e3841",
          name: "\u5251\u950b\u7d2b",
          pinyin: "jianfengzi",
        },
        {
          CMYK: [79, 74, 49, 60],
          RGB: [50, 47, 59],
          hex: "#322f3b",
          name: "\u9f99\u8475\u7d2b",
          pinyin: "longkuizi",
        },
        {
          CMYK: [90, 84, 50, 69],
          RGB: [34, 32, 46],
          hex: "#22202e",
          name: "\u6697\u9f99\u80c6\u7d2b",
          pinyin: "anlongdanzi",
        },
        {
          CMYK: [98, 93, 48, 73],
          RGB: [31, 32, 64],
          hex: "#1f2040",
          name: "\u6676\u77f3\u7d2b",
          pinyin: "jingshizi",
        },
        {
          CMYK: [100, 94, 52, 77],
          RGB: [19, 17, 36],
          hex: "#131124",
          name: "\u6697\u84dd\u7d2b",
          pinyin: "anlanzi",
        },
        {
          CMYK: [95, 46, 10, 1],
          RGB: [39, 117, 182],
          hex: "#2775b6",
          name: "\u666f\u6cf0\u84dd",
          pinyin: "jingtailan",
        },
        {
          CMYK: [96, 47, 11, 1],
          RGB: [36, 116, 181],
          hex: "#2474b5",
          name: "\u5c3c\u7f57\u84dd",
          pinyin: "niluolan",
        },
        {
          CMYK: [25, 6, 10, 0],
          RGB: [208, 223, 230],
          hex: "#d0dfe6",
          name: "\u8fdc\u5929\u84dd",
          pinyin: "yuantianlan",
        },
        {
          CMYK: [53, 19, 15, 1],
          RGB: [147, 181, 207],
          hex: "#93b5cf",
          name: "\u661f\u84dd",
          pinyin: "xinglan",
        },
        {
          CMYK: [74, 27, 16, 2],
          RGB: [97, 154, 195],
          hex: "#619ac3",
          name: "\u7fbd\u6247\u8c46\u84dd",
          pinyin: "yushandoulan",
        },
        {
          CMYK: [95, 45, 10, 1],
          RGB: [35, 118, 183],
          hex: "#2376b7",
          name: "\u82b1\u9752",
          pinyin: "huaqing",
        },
        {
          CMYK: [78, 27, 17, 2],
          RGB: [86, 152, 195],
          hex: "#5698c3",
          name: "\u775b\u84dd",
          pinyin: "jinglan",
        },
        {
          CMYK: [99, 44, 10, 1],
          RGB: [33, 119, 184],
          hex: "#2177b8",
          name: "\u8679\u84dd",
          pinyin: "honglan",
        },
        {
          CMYK: [43, 4, 16, 0],
          RGB: [176, 213, 223],
          hex: "#b0d5df",
          name: "\u6e56\u6c34\u84dd",
          pinyin: "hushuilan",
        },
        {
          CMYK: [59, 12, 19, 0],
          RGB: [138, 188, 209],
          hex: "#8abcd1",
          name: "\u79cb\u6ce2\u84dd",
          pinyin: "qiubolan",
        },
        {
          CMYK: [73, 17, 20, 1],
          RGB: [102, 169, 201],
          hex: "#66a9c9",
          name: "\u6da7\u77f3\u84dd",
          pinyin: "jianshilan",
        },
        {
          CMYK: [93, 36, 15, 2],
          RGB: [41, 131, 187],
          hex: "#2983bb",
          name: "\u6f6e\u84dd",
          pinyin: "chaolan",
        },
        {
          CMYK: [99, 48, 11, 1],
          RGB: [23, 114, 180],
          hex: "#1772b4",
          name: "\u7fa4\u9752",
          pinyin: "qunqing",
        },
        {
          CMYK: [74, 2, 24, 0],
          RGB: [99, 187, 208],
          hex: "#63bbd0",
          name: "\u9701\u9752",
          pinyin: "jiqing",
        },
        {
          CMYK: [77, 7, 24, 0],
          RGB: [92, 179, 204],
          hex: "#5cb3cc",
          name: "\u78a7\u9752",
          pinyin: "biqing",
        },
        {
          CMYK: [94, 32, 17, 3],
          RGB: [36, 134, 185],
          hex: "#2486b9",
          name: "\u5b9d\u77f3\u84dd",
          pinyin: "baoshilan",
        },
        {
          CMYK: [98, 43, 14, 2],
          RGB: [22, 119, 179],
          hex: "#1677b3",
          name: "\u5929\u84dd",
          pinyin: "tianlan",
        },
        {
          CMYK: [100, 52, 11, 1],
          RGB: [18, 107, 174],
          hex: "#126bae",
          name: "\u67cf\u6797\u84dd",
          pinyin: "bolinlan",
        },
        {
          CMYK: [92, 10, 25, 1],
          RGB: [34, 162, 195],
          hex: "#22a2c3",
          name: "\u6d77\u9752",
          pinyin: "haiqing",
        },
        {
          CMYK: [94, 16, 23, 3],
          RGB: [26, 148, 188],
          hex: "#1a94bc",
          name: "\u94b4\u84dd",
          pinyin: "gulan",
        },
        {
          CMYK: [95, 25, 20, 4],
          RGB: [21, 139, 184],
          hex: "#158bb8",
          name: "\u9e22\u5c3e\u84dd",
          pinyin: "yuanweilan",
        },
        {
          CMYK: [98, 42, 16, 3],
          RGB: [17, 119, 176],
          hex: "#1177b0",
          name: "\u7275\u725b\u82b1\u84dd",
          pinyin: "qianniuhualan",
        },
        {
          CMYK: [100, 65, 11, 1],
          RGB: [15, 89, 164],
          hex: "#0f59a4",
          name: "\u98de\u71d5\u8349\u84dd",
          pinyin: "feiyancaolan",
        },
        {
          CMYK: [95, 47, 14, 2],
          RGB: [43, 115, 175],
          hex: "#2b73af",
          name: "\u54c1\u84dd",
          pinyin: "pinlan",
        },
        {
          CMYK: [24, 14, 16, 1],
          RGB: [205, 209, 211],
          hex: "#cdd1d3",
          name: "\u94f6\u9c7c\u767d",
          pinyin: "yinyubai",
        },
        {
          CMYK: [93, 49, 17, 3],
          RGB: [49, 112, 167],
          hex: "#3170a7",
          name: "\u5b89\u5b89\u84dd",
          pinyin: "ananlan",
        },
        {
          CMYK: [64, 52, 39, 28],
          RGB: [94, 97, 109],
          hex: "#5e616d",
          name: "\u9c7c\u5c3e\u7070",
          pinyin: "yuweihui",
        },
        {
          CMYK: [78, 60, 40, 31],
          RGB: [71, 81, 100],
          hex: "#475164",
          name: "\u9cb8\u9c7c\u7070",
          pinyin: "jingyuhui",
        },
        {
          CMYK: [69, 64, 52, 59],
          RGB: [255, 254, 250],
          hex: "#fffefa",
          name: "\u6d77\u53c2\u7070",
          pinyin: "haishenhui",
        },
        {
          CMYK: [76, 70, 51, 60],
          RGB: [53, 51, 60],
          hex: "#35333c",
          name: "\u6c99\u9c7c\u7070",
          pinyin: "shayuhui",
        },
        {
          CMYK: [100, 89, 54, 79],
          RGB: [15, 20, 35],
          hex: "#0f1423",
          name: "\u94a2\u84dd",
          pinyin: "ganglan",
        },
        {
          CMYK: [35, 13, 13, 0],
          RGB: [186, 204, 217],
          hex: "#baccd9",
          name: "\u4e91\u6c34\u84dd",
          pinyin: "yunshuilan",
        },
        {
          CMYK: [55, 20, 18, 1],
          RGB: [143, 178, 201],
          hex: "#8fb2c9",
          name: "\u6674\u5c71\u84dd",
          pinyin: "qingshanlan",
        },
        {
          CMYK: [100, 60, 8, 1],
          RGB: [22, 97, 171],
          hex: "#1661ab",
          name: "\u975b\u9752",
          pinyin: "dianqing",
        },
        {
          CMYK: [29, 16, 17, 1],
          RGB: [196, 203, 207],
          hex: "#c4cbcf",
          name: "\u5927\u7406\u77f3\u7070",
          pinyin: "dalishihui",
        },
        {
          CMYK: [100, 67, 16, 3],
          RGB: [21, 85, 154],
          hex: "#15559a",
          name: "\u6d77\u6d9b\u84dd",
          pinyin: "haitaolan",
        },
        {
          CMYK: [81, 41, 24, 8],
          RGB: [78, 124, 161],
          hex: "#4e7ca1",
          name: "\u8776\u7fc5\u84dd",
          pinyin: "diechilan",
        },
        {
          CMYK: [93, 50, 21, 6],
          RGB: [52, 108, 156],
          hex: "#346c9c",
          name: "\u6d77\u519b\u84dd",
          pinyin: "haijunlan",
        },
        {
          CMYK: [77, 68, 54, 66],
          RGB: [47, 47, 53],
          hex: "#2f2f35",
          name: "\u6c34\u725b\u7070",
          pinyin: "shuiniuhui",
        },
        {
          CMYK: [80, 70, 53, 65],
          RGB: [45, 46, 54],
          hex: "#2d2e36",
          name: "\u725b\u89d2\u7070",
          pinyin: "niujiaohui",
        },
        {
          CMYK: [100, 86, 54, 78],
          RGB: [19, 24, 36],
          hex: "#131824",
          name: "\u71d5\u9894\u84dd",
          pinyin: "yanhanlan",
        },
        {
          CMYK: [21, 6, 10, 0],
          RGB: [216, 227, 231],
          hex: "#d8e3e7",
          name: "\u4e91\u5cf0\u767d",
          pinyin: "yunfengbai",
        },
        {
          CMYK: [32, 8, 13, 0],
          RGB: [195, 215, 223],
          hex: "#c3d7df",
          name: "\u4e95\u5929\u84dd",
          pinyin: "jingtianlan",
        },
        {
          CMYK: [91, 24, 22, 4],
          RGB: [47, 144, 185],
          hex: "#2f90b9",
          name: "\u4e91\u5c71\u84dd",
          pinyin: "yunshanlan",
        },
        {
          CMYK: [96, 34, 18, 4],
          RGB: [23, 129, 181],
          hex: "#1781b5",
          name: "\u91c9\u84dd",
          pinyin: "youlan",
        },
        {
          CMYK: [28, 12, 17, 0],
          RGB: [199, 210, 212],
          hex: "#c7d2d4",
          name: "\u9e25\u84dd",
          pinyin: "oulan",
        },
        {
          CMYK: [100, 53, 21, 6],
          RGB: [17, 101, 154],
          hex: "#11659a",
          name: "\u642a\u78c1\u84dd",
          pinyin: "tangcilan",
        },
        {
          CMYK: [29, 18, 21, 2],
          RGB: [192, 196, 195],
          hex: "#c0c4c3",
          name: "\u6708\u5f71\u767d",
          pinyin: "yueyingbai",
        },
        {
          CMYK: [36, 20, 23, 2],
          RGB: [178, 187, 190],
          hex: "#b2bbbe",
          name: "\u661f\u7070",
          pinyin: "xinghui",
        },
        {
          CMYK: [70, 38, 36, 18],
          RGB: [94, 121, 135],
          hex: "#5e7987",
          name: "\u6de1\u84dd\u7070",
          pinyin: "danlanhui",
        },
        {
          CMYK: [100, 68, 32, 20],
          RGB: [20, 74, 116],
          hex: "#144a74",
          name: "\u9dc3\u84dd",
          pinyin: "yanlan",
        },
        {
          CMYK: [55, 40, 40, 23],
          RGB: [116, 120, 122],
          hex: "#74787a",
          name: "\u5ae9\u7070",
          pinyin: "nenhui",
        },
        {
          CMYK: [77, 50, 41, 31],
          RGB: [73, 92, 105],
          hex: "#495c69",
          name: "\u6218\u8230\u7070",
          pinyin: "zhanjianhui",
        },
        {
          CMYK: [67, 57, 49, 49],
          RGB: [71, 72, 76],
          hex: "#47484c",
          name: "\u74e6\u7f50\u7070",
          pinyin: "waguanhui",
        },
        {
          CMYK: [87, 69, 51, 58],
          RGB: [43, 51, 62],
          hex: "#2b333e",
          name: "\u9752\u7070",
          pinyin: "qinghui",
        },
        {
          CMYK: [100, 77, 50, 62],
          RGB: [28, 41, 56],
          hex: "#1c2938",
          name: "\u9e3d\u84dd",
          pinyin: "gelan",
        },
        {
          CMYK: [100, 82, 51, 64],
          RGB: [20, 35, 52],
          hex: "#142334",
          name: "\u94a2\u9752",
          pinyin: "gangqing",
        },
        {
          CMYK: [100, 84, 51, 68],
          RGB: [16, 31, 48],
          hex: "#101f30",
          name: "\u6697\u84dd",
          pinyin: "anlan",
        },
        {
          CMYK: [11, 0, 8, 0],
          RGB: [238, 247, 242],
          hex: "#eef7f2",
          name: "\u6708\u767d",
          pinyin: "yuebai",
        },
        {
          CMYK: [33, 0, 14, 0],
          RGB: [198, 230, 232],
          hex: "#c6e6e8",
          name: "\u6d77\u5929\u84dd",
          pinyin: "haitianlan",
        },
        {
          CMYK: [57, 0, 22, 0],
          RGB: [147, 213, 220],
          hex: "#93d5dc",
          name: "\u6e05\u6c34\u84dd",
          pinyin: "qingshuilan",
        },
        {
          CMYK: [79, 0, 27, 0],
          RGB: [81, 196, 211],
          hex: "#51c4d3",
          name: "\u7011\u5e03\u84dd",
          pinyin: "pubulan",
        },
        {
          CMYK: [89, 0, 29, 0],
          RGB: [41, 183, 203],
          hex: "#29b7cb",
          name: "\u851a\u84dd",
          pinyin: "weilan",
        },
        {
          CMYK: [92, 0, 28, 0],
          RGB: [14, 176, 201],
          hex: "#0eb0c9",
          name: "\u5b54\u96c0\u84dd",
          pinyin: "kongquelan",
        },
        {
          CMYK: [93, 0, 31, 0],
          RGB: [16, 174, 194],
          hex: "#10aec2",
          name: "\u7538\u5b50\u84dd",
          pinyin: "dianzilan",
        },
        {
          CMYK: [78, 36, 0, 0],
          RGB: [87, 195, 194],
          hex: "#57c3c2",
          name: "\u77f3\u7eff",
          pinyin: "shilv",
        },
        {
          CMYK: [40, 0, 30, 0],
          RGB: [185, 222, 201],
          hex: "#b9dec9",
          name: "\u7af9\u7bc1\u7eff",
          pinyin: "zhuhuanglv",
        },
        {
          CMYK: [64, 0, 46, 0],
          RGB: [131, 203, 172],
          hex: "#83cbac",
          name: "\u7c89\u7eff",
          pinyin: "fenlv",
        },
        {
          CMYK: [95, 0, 52, 0],
          RGB: [18, 170, 156],
          hex: "#12aa9c",
          name: "\u7f8e\u8776\u7eff",
          pinyin: "meidielv",
        },
        {
          CMYK: [75, 0, 61, 0],
          RGB: [102, 193, 140],
          hex: "#66c18c",
          name: "\u6bdb\u7eff",
          pinyin: "maolv",
        },
        {
          CMYK: [78, 0, 62, 0],
          RGB: [93, 190, 138],
          hex: "#5dbe8a",
          name: "\u853b\u68a2\u7eff",
          pinyin: "koushaolv",
        },
        {
          CMYK: [81, 0, 62, 0],
          RGB: [85, 187, 138],
          hex: "#55bb8a",
          name: "\u9ea6\u82d7\u7eff",
          pinyin: "maimiaolv",
        },
        {
          CMYK: [86, 0, 63, 0],
          RGB: [69, 183, 135],
          hex: "#45b787",
          name: "\u86d9\u7eff",
          pinyin: "walv",
        },
        {
          CMYK: [92, 0, 64, 0],
          RGB: [43, 174, 133],
          hex: "#2bae85",
          name: "\u94dc\u7eff",
          pinyin: "tonglv",
        },
        {
          CMYK: [96, 0, 64, 0],
          RGB: [27, 167, 132],
          hex: "#1ba784",
          name: "\u7af9\u7eff",
          pinyin: "zhulv",
        },
        {
          CMYK: [98, 2, 64, 0],
          RGB: [18, 161, 130],
          hex: "#12a182",
          name: "\u84dd\u7eff",
          pinyin: "lanlv",
        },
        {
          CMYK: [32, 7, 18, 0],
          RGB: [196, 215, 214],
          hex: "#c4d7d6",
          name: "\u7a79\u7070",
          pinyin: "qionghui",
        },
        {
          CMYK: [94, 11, 33, 1],
          RGB: [30, 158, 179],
          hex: "#1e9eb3",
          name: "\u7fe0\u84dd",
          pinyin: "cuilan",
        },
        {
          CMYK: [96, 16, 31, 3],
          RGB: [15, 149, 176],
          hex: "#0f95b0",
          name: "\u80c6\u77fe\u84dd",
          pinyin: "danfanlan",
        },
        {
          CMYK: [96, 18, 34, 4],
          RGB: [20, 145, 168],
          hex: "#1491a8",
          name: "\u6a2b\u9e1f\u84dd",
          pinyin: "jianniaolan",
        },
        {
          CMYK: [64, 18, 32, 2],
          RGB: [124, 171, 177],
          hex: "#7cabb1",
          name: "\u95ea\u84dd",
          pinyin: "shanlan",
        },
        {
          CMYK: [40, 24, 32, 6],
          RGB: [164, 172, 167],
          hex: "#a4aca7",
          name: "\u51b0\u5c71\u84dd",
          pinyin: "bingshanlan",
        },
        {
          CMYK: [56, 26, 36, 7],
          RGB: [134, 157, 157],
          hex: "#869d9d",
          name: "\u867e\u58f3\u9752",
          pinyin: "xiakeqing",
        },
        {
          CMYK: [71, 28, 39, 10],
          RGB: [100, 142, 147],
          hex: "#648e93",
          name: "\u665a\u6ce2\u84dd",
          pinyin: "wanbolan",
        },
        {
          CMYK: [89, 27, 41, 13],
          RGB: [59, 129, 140],
          hex: "#3b818c",
          name: "\u873b\u8713\u84dd",
          pinyin: "qingtinglan",
        },
        {
          CMYK: [99, 33, 38, 21],
          RGB: [18, 110, 130],
          hex: "#126e82",
          name: "\u7389\u922b\u84dd",
          pinyin: "yuqinlan",
        },
        {
          CMYK: [57, 37, 42, 21],
          RGB: [115, 124, 123],
          hex: "#737c7b",
          name: "\u57a9\u7070",
          pinyin: "ehui",
        },
        {
          CMYK: [65, 40, 44, 26],
          RGB: [97, 113, 114],
          hex: "#617172",
          name: "\u590f\u4e91\u7070",
          pinyin: "xiayunhui",
        },
        {
          CMYK: [100, 52, 46, 43],
          RGB: [19, 72, 87],
          hex: "#134857",
          name: "\u82cd\u84dd",
          pinyin: "canglan",
        },
        {
          CMYK: [67, 53, 51, 50],
          RGB: [71, 75, 76],
          hex: "#474b4c",
          name: "\u9ec4\u660f\u7070",
          pinyin: "huanghunhui",
        },
        {
          CMYK: [94, 58, 54, 60],
          RGB: [33, 55, 61],
          hex: "#21373d",
          name: "\u7070\u84dd",
          pinyin: "huilan",
        },
        {
          CMYK: [100, 64, 56, 68],
          RGB: [19, 44, 51],
          hex: "#132c33",
          name: "\u6df1\u7070\u84dd",
          pinyin: "shenhuilan",
        },
        {
          CMYK: [49, 5, 37, 0],
          RGB: [164, 202, 182],
          hex: "#a4cab6",
          name: "\u7389\u7c2a\u7eff",
          pinyin: "yuzanlv",
        },
        {
          CMYK: [96, 12, 66, 2],
          RGB: [44, 150, 120],
          hex: "#2c9678",
          name: "\u9752\u77fe\u7eff",
          pinyin: "qingfanlv",
        },
        {
          CMYK: [52, 11, 37, 0],
          RGB: [154, 190, 175],
          hex: "#9abeaf",
          name: "\u8349\u539f\u8fdc\u7eff",
          pinyin: "caoyuanyuanlv",
        },
        {
          CMYK: [74, 14, 51, 1],
          RGB: [105, 167, 148],
          hex: "#69a794",
          name: "\u68a7\u679d\u7eff",
          pinyin: "wuzhilv",
        },
        {
          CMYK: [55, 16, 40, 1],
          RGB: [146, 179, 165],
          hex: "#92b3a5",
          name: "\u6d6a\u82b1\u7eff",
          pinyin: "langhualv",
        },
        {
          CMYK: [99, 23, 70, 10],
          RGB: [36, 128, 103],
          hex: "#248067",
          name: "\u6d77\u738b\u7eff",
          pinyin: "haiwanglv",
        },
        {
          CMYK: [88, 24, 61, 9],
          RGB: [66, 134, 117],
          hex: "#428675",
          name: "\u4e9a\u4e01\u7eff",
          pinyin: "yadinglv",
        },
        {
          CMYK: [40, 27, 36, 8],
          RGB: [159, 163, 154],
          hex: "#9fa39a",
          name: "\u954d\u7070",
          pinyin: "niehui",
        },
        {
          CMYK: [52, 28, 42, 10],
          RGB: [138, 152, 142],
          hex: "#8a988e",
          name: "\u660e\u7070",
          pinyin: "minghui",
        },
        {
          CMYK: [63, 31, 50, 14],
          RGB: [112, 136, 125],
          hex: "#70887d",
          name: "\u6de1\u7eff\u7070",
          pinyin: "danlvhui",
        },
        {
          CMYK: [82, 32, 60, 20],
          RGB: [73, 117, 104],
          hex: "#497568",
          name: "\u98de\u6cc9\u7eff",
          pinyin: "feiquanlv",
        },
        {
          CMYK: [62, 43, 52, 34],
          RGB: [93, 101, 95],
          hex: "#5d655f",
          name: "\u72fc\u70df\u7070",
          pinyin: "langyanhui",
        },
        {
          CMYK: [85, 44, 64, 52],
          RGB: [49, 74, 67],
          hex: "#314a43",
          name: "\u7eff\u7070",
          pinyin: "lvhui",
        },
        {
          CMYK: [93, 46, 70, 61],
          RGB: [34, 62, 54],
          hex: "#223e36",
          name: "\u82cd\u7eff",
          pinyin: "canglv",
        },
        {
          CMYK: [98, 46, 73, 63],
          RGB: [26, 59, 50],
          hex: "#1a3b32",
          name: "\u6df1\u6d77\u7eff",
          pinyin: "shenhailv",
        },
        {
          CMYK: [67, 60, 57, 68],
          RGB: [54, 52, 51],
          hex: "#363433",
          name: "\u957f\u77f3\u7070",
          pinyin: "changshihui",
        },
        {
          CMYK: [82, 60, 65, 80],
          RGB: [31, 38, 35],
          hex: "#1f2623",
          name: "\u82f7\u84dd\u7eff",
          pinyin: "ganlanlv",
        },
        {
          CMYK: [90, 62, 67, 86],
          RGB: [20, 30, 27],
          hex: "#141e1b",
          name: "\u83bd\u4e1b\u7eff",
          pinyin: "mangconglv",
        },
        {
          CMYK: [33, 1, 29, 0],
          RGB: [198, 223, 200],
          hex: "#c6dfc8",
          name: "\u6de1\u7fe0\u7eff",
          pinyin: "dancuilv",
        },
        {
          CMYK: [53, 1, 44, 0],
          RGB: [158, 204, 171],
          hex: "#9eccab",
          name: "\u660e\u7eff",
          pinyin: "minglv",
        },
        {
          CMYK: [75, 0, 59, 0],
          RGB: [104, 184, 142],
          hex: "#68b88e",
          name: "\u7530\u56ed\u7eff",
          pinyin: "tianyuanlv",
        },
        {
          CMYK: [98, 0, 82, 0],
          RGB: [32, 161, 98],
          hex: "#20a162",
          name: "\u7fe0\u7eff",
          pinyin: "cuilv",
        },
        {
          CMYK: [78, 6, 62, 0],
          RGB: [97, 172, 133],
          hex: "#61ac85",
          name: "\u6de1\u7eff",
          pinyin: "danlv",
        },
        {
          CMYK: [90, 7, 73, 0],
          RGB: [64, 160, 112],
          hex: "#40a070",
          name: "\u8471\u7eff",
          pinyin: "conglv",
        },
        {
          CMYK: [99, 10, 91, 2],
          RGB: [34, 148, 83],
          hex: "#229453",
          name: "\u5b54\u96c0\u7eff",
          pinyin: "kongquelv",
        },
        {
          CMYK: [27, 11, 27, 1],
          RGB: [202, 211, 195],
          hex: "#cad3c3",
          name: "\u827e\u7eff",
          pinyin: "ailv",
        },
        {
          CMYK: [92, 14, 76, 2],
          RGB: [60, 149, 102],
          hex: "#3c9566",
          name: "\u87fe\u7eff",
          pinyin: "chanlv",
        },
        {
          CMYK: [100, 17, 92, 5],
          RGB: [32, 137, 77],
          hex: "#20894d",
          name: "\u5bab\u6bbf\u7eff",
          pinyin: "gongdianlv",
        },
        {
          CMYK: [61, 19, 52, 3],
          RGB: [131, 167, 141],
          hex: "#83a78d",
          name: "\u677e\u971c\u7eff",
          pinyin: "songshuanglv",
        },
        {
          CMYK: [81, 19, 67, 4],
          RGB: [87, 149, 114],
          hex: "#579572",
          name: "\u86cb\u767d\u77f3\u7eff",
          pinyin: "danbaishilv",
        },
        {
          CMYK: [100, 22, 90, 10],
          RGB: [32, 127, 76],
          hex: "#207f4c",
          name: "\u8584\u8377\u7eff",
          pinyin: "bohelv",
        },
        {
          CMYK: [66, 29, 58, 12],
          RGB: [110, 139, 116],
          hex: "#6e8b74",
          name: "\u74e6\u677e\u7eff",
          pinyin: "wasonglv",
        },
        {
          CMYK: [100, 31, 91, 25],
          RGB: [26, 104, 64],
          hex: "#1a6840",
          name: "\u8377\u53f6\u7eff",
          pinyin: "heyelv",
        },
        {
          CMYK: [62, 42, 56, 34],
          RGB: [94, 102, 91],
          hex: "#5e665b",
          name: "\u7530\u87ba\u7eff",
          pinyin: "tianluolv",
        },
        {
          CMYK: [74, 42, 65, 40],
          RGB: [72, 91, 77],
          hex: "#485b4d",
          name: "\u767d\u5c48\u83dc\u7eff",
          pinyin: "baiqucailv",
        },
        {
          CMYK: [64, 57, 60, 67],
          RGB: [57, 55, 51],
          hex: "#393733",
          name: "\u6cb3\u8c5a\u7070",
          pinyin: "hetunhui",
        },
        {
          CMYK: [68, 56, 60, 66],
          RGB: [55, 56, 52],
          hex: "#373834",
          name: "\u84bd\u6cb9\u7eff",
          pinyin: "enyoulv",
        },
        {
          CMYK: [76, 56, 75, 72],
          RGB: [43, 49, 44],
          hex: "#2b312c",
          name: "\u69f2\u5bc4\u751f\u7eff",
          pinyin: "hujishenglv",
        },
        {
          CMYK: [91, 60, 76, 83],
          RGB: [21, 35, 27],
          hex: "#15231b",
          name: "\u4e91\u6749\u7eff",
          pinyin: "yunshanlv",
        },
        {
          CMYK: [9, 1, 14, 0],
          RGB: [240, 245, 229],
          hex: "#f0f5e5",
          name: "\u5ae9\u83ca\u7eff",
          pinyin: "nenjulv",
        },
        {
          CMYK: [20, 1, 23, 0],
          RGB: [223, 236, 213],
          hex: "#dfecd5",
          name: "\u827e\u80cc\u7eff",
          pinyin: "aibeilv",
        },
        {
          CMYK: [47, 0, 49, 0],
          RGB: [173, 213, 162],
          hex: "#add5a2",
          name: "\u5609\u9675\u6c34\u7eff",
          pinyin: "jialingshuilv",
        },
        {
          CMYK: [89, 0, 96, 0],
          RGB: [65, 179, 73],
          hex: "#41b349",
          name: "\u7389\u9ad3\u7eff",
          pinyin: "yusuilv",
        },
        {
          CMYK: [88, 0, 99, 0],
          RGB: [67, 178, 68],
          hex: "#43b244",
          name: "\u9c9c\u7eff",
          pinyin: "xianlv",
        },
        {
          CMYK: [90, 0, 100, 0],
          RGB: [65, 174, 60],
          hex: "#41ae3c",
          name: "\u5b9d\u77f3\u7eff",
          pinyin: "baoshilv",
        },
        {
          CMYK: [18, 4, 33, 0],
          RGB: [226, 231, 191],
          hex: "#e2e7bf",
          name: "\u6d77\u6cac\u7eff",
          pinyin: "haimeilv",
        },
        {
          CMYK: [28, 4, 44, 0],
          RGB: [208, 222, 170],
          hex: "#d0deaa",
          name: "\u59da\u9ec4",
          pinyin: "yaohuang",
        },
        {
          CMYK: [44, 3, 61, 0],
          RGB: [178, 207, 135],
          hex: "#b2cf87",
          name: "\u6a44\u6984\u77f3\u7eff",
          pinyin: "ganlanshilv",
        },
        {
          CMYK: [62, 0, 76, 0],
          RGB: [140, 194, 105],
          hex: "#8cc269",
          name: "\u6c34\u7eff",
          pinyin: "shuilv",
        },
        {
          CMYK: [42, 3, 67, 0],
          RGB: [183, 208, 122],
          hex: "#b7d07a",
          name: "\u82a6\u82c7\u7eff",
          pinyin: "luweilv",
        },
        {
          CMYK: [28, 6, 66, 0],
          RGB: [210, 217, 122],
          hex: "#d2d97a",
          name: "\u69d0\u82b1\u9ec4\u7eff",
          pinyin: "huaihuahuanglv",
        },
        {
          CMYK: [41, 4, 76, 0],
          RGB: [186, 207, 101],
          hex: "#bacf65",
          name: "\u82f9\u679c\u7eff",
          pinyin: "pingguolv",
        },
        {
          CMYK: [58, 1, 88, 0],
          RGB: [150, 194, 78],
          hex: "#96c24e",
          name: "\u82bd\u7eff",
          pinyin: "yalv",
        },
        {
          CMYK: [19, 9, 84, 1],
          RGB: [226, 216, 73],
          hex: "#e2d849",
          name: "\u8776\u9ec4",
          pinyin: "diehuang",
        },
        {
          CMYK: [38, 8, 94, 1],
          RGB: [190, 201, 54],
          hex: "#bec936",
          name: "\u6a44\u6984\u9ec4\u7eff",
          pinyin: "ganlanhuanglv",
        },
        {
          CMYK: [81, 0, 100, 0],
          RGB: [91, 174, 35],
          hex: "#5bae23",
          name: "\u9e66\u9e49\u7eff",
          pinyin: "yingwulv",
        },
        {
          CMYK: [84, 64, 94, 45],
          RGB: [37, 61, 36],
          hex: "#253d24",
          name: "\u6cb9\u7eff",
          pinyin: "youlv",
        },
        {
          CMYK: [0, 1, 4, 0],
          RGB: [255, 254, 248],
          hex: "#fffef8",
          name: "\u8c61\u7259\u767d",
          pinyin: "xiangyabai",
        },
        {
          CMYK: [3, 5, 8, 0],
          RGB: [248, 244, 237],
          hex: "#f8f4ed",
          name: "\u6c49\u767d\u7389",
          pinyin: "hanbaiyu",
        },
        {
          CMYK: [0, 1, 3, 0],
          RGB: [255, 254, 249],
          hex: "#fffef9",
          name: "\u96ea\u767d",
          pinyin: "xuebai",
        },
        {
          CMYK: [4, 4, 8, 0],
          RGB: [247, 244, 237],
          hex: "#f7f4ed",
          name: "\u9c7c\u809a\u767d",
          pinyin: "yudubai",
        },
        {
          CMYK: [12, 12, 16, 0],
          RGB: [228, 223, 215],
          hex: "#e4dfd7",
          name: "\u73cd\u73e0\u7070",
          pinyin: "zhenzhuhui",
        },
        {
          CMYK: [16, 15, 20, 1],
          RGB: [218, 212, 203],
          hex: "#dad4cb",
          name: "\u6d45\u7070",
          pinyin: "qianhui",
        },
        {
          CMYK: [28, 5, 30, 5],
          RGB: [187, 181, 172],
          hex: "#bbb5ac",
          name: "\u94c5\u7070",
          pinyin: "qianhui",
        },
        {
          CMYK: [28, 25, 30, 5],
          RGB: [187, 181, 172],
          hex: "#bbb5ac",
          name: "\u4e2d\u7070",
          pinyin: "zhonghui",
        },
        {
          CMYK: [42, 40, 43, 23],
          RGB: [134, 126, 118],
          hex: "#867e76",
          name: "\u74e6\u7070",
          pinyin: "wahui",
        },
        {
          CMYK: [43, 40, 44, 42],
          RGB: [132, 124, 116],
          hex: "#847c74",
          name: "\u591c\u7070",
          pinyin: "yehui",
        },
        {
          CMYK: [42, 42, 45, 27],
          RGB: [128, 118, 110],
          hex: "#80766e",
          name: "\u96c1\u7070",
          pinyin: "yanhui",
        },
        {
          CMYK: [42, 42, 46, 27],
          RGB: [129, 119, 110],
          hex: "#81776e",
          name: "\u6df1\u7070",
          pinyin: "shenhui",
        },
      ],
      bgColor: "#bbb",
      cmyk: [
        {
          title: 'C',
          color: '#0093D3'
        },
        {
          title: 'M',
          color: '#CC006B'
        },
        {
          title: 'Y',
          color: '#FFF10C'
        },
        {
          title: 'K',
          color: '#000000'
        },
      ],
      currentCmky: [0, 0, 0, 0],
      RGB: [0, 0, 0],
      chineseName: '中国色',
      chinsesPinyin: 'ZHONGGUOSE',
      tipShow: false
    };
  },
  methods: {
    getColorItem(item) {
      this.bgColor = item.hex;
      this.currentCmky = item.CMYK
      this.RGB = item.RGB
      this.chineseName = item.name
      this.chinsesPinyin = item.pinyin
      this.tipShow = true
      setTimeout(() => {
        this.tipShow = false
      }, 1000)
      document.title = `${this.chineseName} - 中国色 - 中国传统颜色  - 陈佳兵仿制`
    },
    onCopy(e) {
      console.log(e.text)
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/images/bg.png") #bbb;
    transition: all 1s linear;
  }
  .container {
    width: 960px;
    height: 100%;
    margin: 40px auto;
    position: relative;
    .logo {
      position: absolute;
      left: 909px;
      .logo-bg {
        width: 60px;
        height: 520px;
        background: url("../assets/images/logo.png") no-repeat;
        position: fixed;
      }
    }
    .colors {
      width: 480px;
      height: 100%;
      position: absolute;
      left: -23px;
      top: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .color-box {
        width: 50px;
        height: 284px;
        margin-right: 20px;
        margin-top: 20px;
      }
    }
    .content {
      position: absolute;
      left: 434px;
      top: 20px;
      height: 507px;
      width: 410px;
      .content-color {
        position: fixed;
        width: 100px;
        .circle-container {
          height: 86px;
          position: relative;
          width: 50px;
          z-index: 500;
          border-top: 1px solid #fff;
          &:nth-child(2) {
            .circle-right,.circle-left {
              .line {
                background-position: 0 -50px;
              }
            }
          }
          &:nth-child(3) {
            .circle-right,.circle-left {
              .line {
                background-position: 0 -100px;
              }
            }
          }
          &:nth-child(4) {
            .circle-right,.circle-left {
              .line {
                background-position: 0 -150px;
              }
            }
          }
          .circle-title {
            height: 26px;
            line-height: 26px;
            color: #fff;
            font-size: 10px;
          }
          .circle {
            background: url("../assets/images/circle.png") no-repeat;
            display: block;
            height: 50px;
            left: 0;
            position: absolute;
            top: 26px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: sans-serif;
            font-size: 24px;
            font-weight: 300;
          }
          .circle-right {
            position: absolute;
            left: 0;
            top: 26px;
            width: 50px;
            height: 50px;
            mask-image: url("../assets/images/circle_r.png");
            .line {
              display: block;
              height: 50px;
              left: 1px;
              position: absolute;
              top: 0;
              width: 50px;
              background: url("../assets/images/colorBox.png") no-repeat;
              background-position:  0 0;
              transform: rotate(180deg);
              transition: all 0.5s linear;
            }
          }
          .circle-left {
            position: absolute;
            left: 0;
            top: 26px;
            width: 50px;
            height: 50px;
            mask-image: url("../assets/images/circle_l.png");
            .line {
              display: block;
              height: 50px;
              left: 1px;
              position: absolute;
              top: 0;
              width: 50px;
              background: url("../assets/images/colorBox.png") no-repeat;
              background-position:  0 0;
              transform: rotate(180deg);
              transition-delay: 0.5s;
              transition: all 0.5s linear;
            }
          }
        }
      }
      .content-num {
        position: fixed;
        top: 410px;
        width: 50px;
      }
      .content-name {
        position: fixed;
        top: 100px;
        color: #fff;
        .content-name__chinese {
          font-family: cursive;
          font-weight: normal;
          font-size: 100px;
          color: #fff;
          margin: 0 auto;
          width: 100px;
          text-align: center;
          background-position: center top;
          background-repeat: no-repeat;
        }
        .content-name__pinyin {
          font-size: 16px;
          width: 500px;
          margin-top: 30px;
          text-align: center;
          font-family: Georgia;
          font-weight: bold;
          -webkit-font-smoothing: antialiased;
        }
      }
      .footer {
        position: fixed;
        width: 500px;
        bottom: 0;
        text-align: center;
        color: #fff;
        font-size: 10px;
        font-family: cursive;
      }
      .tip-box {
        width: 500px;
        position: fixed;
        top: 40px;
        .tip {
          // position: fixed;
          // top: 40px;
          // left: 1200 * $vw;
          width: 180px;
          text-align: center;
          margin: 0 auto;
          padding: 20px;
          border-radius: 5px;
          color: #fff;
          border: 1px solid #fff;
        }
      }
     
    }
  }
}
</style>
